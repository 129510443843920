import * as React from 'react';

const Kluge: React.FC = () => (
  <svg version="1.1" id="Logo" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 136.4882 23.811">
    <g>
      <g>
        <path
          fill="black"
          d="M50.1279,0c-4.3467,0-7.2674,2.383-7.2674,5.9301V5.984c0,3.9015,3.217,4.9464,7.1036,5.8251
                    c2.5374,0.5821,3.3108,1.0322,3.3108,1.9263v0.0539c0,0.9001-0.8389,1.4161-2.3018,1.4161c-2.1926,0-4.2682-0.7476-6.1713-2.2221
                    l-2.7932,3.3054c2.249,1.952,5.3593,3.0257,8.7738,3.0257c4.6845,0,7.5944-2.3136,7.5944-6.0383v-0.0539
                    c0-3.5345-2.8067-4.8633-7.0205-5.771c-2.6963-0.606-3.3939-1.0077-3.3939-1.9534V5.4438c0-0.6454,0.533-1.3352,2.0291-1.3352
                    c1.7128,0,3.5955,0.6447,5.3142,1.8172l2.5089-3.5106C55.729,0.7903,53.2132,0,50.1279,0"
        />
        <polygon
          fill="black"
          points="40.0424,0.4265 34.927,0.4265 30.2033,8.0761 25.4797,0.4265 20.3641,0.4265 20.3641,18.882 
                    24.9396,18.882 24.9396,7.2866 30.1485,15.2302 35.385,7.2307 35.385,18.882 40.0424,18.882 		"
        />
        <g>
          <path
            fill="black"
            d="M10.2072,0C7.136,0.0709,5.6074,1.8098,5.1342,2.4753c-0.0283,0.041-0.1666,0.2446-0.1666,0.2446
                        c-0.9886,1.541-1.4955,3.407-1.3638,5.4308l0.0036,0.053c0.3195,4.9075,4.197,8.4343,9.1366,8.4343
                        c0.2147,0,0.4309-0.0065,0.6491-0.02c1.9502-0.1202,3.5506-0.6114,4.8924-1.5013l-3.9726-2.755
                        c-1.145,1.3532-2.3174,2.2556-4.3076,2.2556c-2.6717,0-4.5527-2.1759-4.5527-4.9622v-0.053c0-2.7066,1.8809-4.9091,4.5527-4.9091
                        c1.8265,0,3.0807,0.8491,4.171,2.1757l3.9807-2.9984c-1.1531-1.5566-2.6876-2.791-4.7834-3.4191
                        c-0.0267-0.0073-0.0514-0.0137-0.0787-0.0214c-0.2748-0.0778-0.5389-0.14-0.7981-0.1955C11.7925,0.0903,11.032,0.0087,10.2072,0"
          />
          <g>
            <path
              fill="black"
              d="M2.2143,7.8215c0-1.0324,0.1636-2.0286,0.4667-2.9658c0.2948-1.1115,0.7722-2.1433,1.4069-3.064
                            L4.0846,1.7936C1.5668,3.5324,0,6.3801,0,9.655v0.0532c0,5.5459,4.3895,9.6057,9.8418,9.6057
                            c2.7101,0,4.6799-0.7312,6.2192-1.8926l-0.0079,0.0023c-0.7977,0.2128-1.6558,0.3464-2.5811,0.4036
                            c-0.235,0.0144-0.4695,0.0216-0.7012,0.0216c-1.2866,0-2.5162-0.2228-3.6525-0.6295
                            C5.1029,15.8597,2.2143,12.1615,2.2143,7.8215"
            />
          </g>
        </g>
      </g>
      <g>
        <path
          fill="black"
          d="M68.6064,0.4561h1.3799v11.096l10.4619-11.096H82.27l-7.8946,8.3822l8.4741,10.0761H81.083L73.4091,9.806
                    l-3.4229,3.6008v5.5076h-1.3799V0.4561z"
        />
        <path fill="black" d="M85.9111,0.1329h1.3525v18.7814h-1.3525V0.1329z" />
        <path
          fill="black"
          d="M102.6929,5.0236v13.8907h-1.3525v-2.3916c-0.7451,1.6397-2.5396,2.7139-4.6924,2.7139
                    c-3.1191,0-5.3276-2.1492-5.3276-5.1854V5.0236h1.3525v8.8394c0,2.4183,1.7114,4.1378,4.1685,4.1378
                    c2.5669,0,4.499-1.7738,4.499-4.1112v-8.866H102.6929z"
        />
        <path
          fill="black"
          d="M119.0875,5.0236v12.4126c0,3.5742-2.7603,6.0998-6.6524,6.0998c-2.1528,0-4.4717-0.7795-5.7691-1.8546
                    l0.6348-1.1017c1.187,1.0219,3.2022,1.7196,4.9688,1.7196c3.2847,0,5.4653-1.961,5.4653-4.8631v-2.8479
                    c-1.0767,2.1492-3.2295,3.3051-5.7139,3.3051c-3.5332,0-6.3487-2.9287-6.3487-6.6093c0-3.6816,2.8154-6.5837,6.3487-6.5837
                    c2.5669,0,4.6372,1.1559,5.7139,3.3051V5.0236H119.0875z M117.7349,11.2841c0-3.1169-2.3462-5.347-5.438-5.347
                    c-2.8984,0-5.2168,2.3907-5.2168,5.347c0,2.9819,2.3184,5.3736,5.2168,5.3736C115.3887,16.6577,117.7349,14.4277,117.7349,11.2841
                    z"
        />
        <path
          fill="black"
          d="M134.3771,16.1739l0.8833,0.9135c-1.2144,1.2899-3.6987,2.1492-5.8794,2.1492
                    c-3.8091,0-7.0938-3.2243-7.0938-7.308s3.1191-7.2282,7.0108-7.2282c4.1685,0,6.7905,3.1169,6.7905,7.8194h-12.4214
                    c0.2759,3.0627,2.6777,5.481,5.6865,5.481C131.2858,18.0009,133.3004,17.3288,134.3771,16.1739z M123.6671,11.2841h11.0689
                    c-0.1934-3.1445-2.208-5.347-5.4102-5.347C126.3448,5.9371,123.9708,8.1938,123.6671,11.2841z"
        />
      </g>
    </g>
  </svg>
);

export default Kluge;

import React, { FC } from 'react';
import { useViewportSize } from 'util/hooks/useViewportSize';
import styled, { css } from 'styled-components';
import createMediaQuery from 'util/createMediaQuery';
import { linkWithoutStyle } from 'styleMixins/listMixins';
import ArrowRight from '../../../components/icons/ArrowRight';
import Image, { Loading } from '../../../components/Image';

const MEDIUM_VIEWPORT_BREAKPOINT = 768;
const VERY_LARGE_VIEWPORT_BREAKPOINT = 2100;

const GetStartedHeroSection: FC = () => {
  const viewportSize = useViewportSize(
    {
      small: 0,
      medium: MEDIUM_VIEWPORT_BREAKPOINT,
      very_large: VERY_LARGE_VIEWPORT_BREAKPOINT,
    },
    'small'
  );

  return (
    <GetStartedHeroSectionCss>
      <ContentWrapperCss>
        <IllustrationWrapperCss>
          {viewportSize === 'medium' ? (
            <Image loading={Loading.EAGER} src="https://dfwibl8ld7pfq.cloudfront.net/image/juridika_laptop_cropped_uclnxz.png" />
          ) : (
            <Image loading={Loading.EAGER} src="https://dfwibl8ld7pfq.cloudfront.net/image/juridika_laptop_g12gdb.png" />
          )}
        </IllustrationWrapperCss>
        <TextWrapperCss>
          <TitleCss>De beste juridiske argumentene. Trygt og effektivt.</TitleCss>
          <SubtitleCss>
            Få de sterkeste juridiske argumentene når du trenger dem mest. Med Juridika får du rettskilder av høyeste kvalitet
            samlet på ett og samme sted.
          </SubtitleCss>
          <ActionsWrapperCss>
            <CTAButtonPrimaryCss href="/prøv-juridika">PRØV GRATIS</CTAButtonPrimaryCss>
            <CTAButtonSecondaryCss href="/priser">
              <span>SE PRISER</span>
              <ArrowRight ml1 />
            </CTAButtonSecondaryCss>
          </ActionsWrapperCss>
        </TextWrapperCss>
      </ContentWrapperCss>
    </GetStartedHeroSectionCss>
  );
};

export default GetStartedHeroSection;

const TextWrapperCss = styled.div`
  color: ${({ theme }) => theme.colorTokens.grey[900]};
  flex: 0 0 53.85%;

  ${createMediaQuery(
    'lg',
    css`
      padding-right: 5rem;
    `
  )}
`;

const TitleCss = styled.h1`
  margin: 0.5rem 0;
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-weight: 500;
  font-size: 2.125rem;

  ${createMediaQuery(
    'lg',
    css`
      font-size: 5rem;
    `
  )}
`;

const SubtitleCss = styled.p`
  margin-bottom: 3rem;
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-size: 1rem;

  ${createMediaQuery(
    'lg',
    css`
      font-size: 1.65rem;
    `
  )}
`;

const IllustrationWrapperCss = styled.div`
  flex: 0 0 46.15%;
  max-width: 25rem;

  ${createMediaQuery(
    'sm',
    css`
      max-width: 70rem;
    `
  )}
`;

const CTAButtonPrimaryCss = styled.a`
  ${linkWithoutStyle}
  background-color: ${({ theme }) => theme.colorTokens.red[600]};
  padding: 10px 32px;
  color: ${({ theme }) => theme.colorTokens.default.white};
  border-radius: 4px;
  display: inline-block;
  font-size: 1.25rem;
  text-align: center;

  ${createMediaQuery(
    'md',
    css`
      font-size: 1.5rem;
    `
  )}
`;

const CTAButtonSecondaryCss = styled.a`
  ${linkWithoutStyle}
  padding: 10px 32px;
  color: ${({ theme }) => theme.colorTokens.default.black};
  display: inline-block;
  font-size: 1.25rem;
  text-align: center;
  font-weight: 600;

  :hover {
    text-decoration: underline;
  }

  ${createMediaQuery(
    'md',
    css`
      font-size: 1.5rem;
    `
  )}
`;

const GetStartedHeroSectionCss = styled.section`
  padding: 32px 20px;
  display: flex;
  justify-content: center;
  background: linear-gradient(rgba(255, 255, 255, 0), ${(props) => props.theme.colorTokens.peach[400]});

  ${createMediaQuery(
    'xs',
    css`
      padding: 2rem 2rem;
    `
  )}

  ${createMediaQuery(
    'sm',
    css`
      padding: 3rem 0 3rem 3rem;
    `
  )}

  ${createMediaQuery(
    'md',
    css`
      padding: 3rem 0 3rem 5rem;
    `
  )}

  ${createMediaQuery(
    'lg',
    css`
      padding: 5rem 0 3rem 8rem;
    `
  )}

  ${createMediaQuery(
    2100,
    css`
      padding: 5rem 0 3rem 0;
    `
  )}
`;

const ActionsWrapperCss = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
`;

const ContentWrapperCss = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 50rem;

  ${createMediaQuery(
    'sm',
    css`
      max-width: 125rem;
      flex-direction: row-reverse;
      justify-content: space-between;
    `
  )}
`;

import * as React from 'react';

const SANDSLogo: React.FC = () => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 365.7 255.1">
    <polygon points="97.9,123 92.1,135.5 103.7,135.5 " />
    <g>
      <path d="M277.6,127.6c0-7.6-4.9-12.3-12.9-12.3h-6.5v24.7h6.5C272.7,139.9,277.6,135.2,277.6,127.6" />
      <path
        d="M55.4,255.1h255.1V0H55.4V255.1z M248.9,106.9h16.4c13.1,0,21.9,8.3,21.9,20.6s-8.8,20.6-21.9,20.6h-16.4V106.9z
		 M163.9,106.9h2.8l26,23.2V107h9.2v41.2h-2.8l-26-23.7v23.7H164L163.9,106.9z M96.8,106.9H99l21.2,41.3h-10.5l-2.6-5.5H88.8
		l-2.5,5.5H75.7L96.8,106.9z"
      />
      <path
        d="M17.6,123.5c-6.4-1.7-8.6-2.7-8.6-5.4s2.4-4.1,6.8-4.1h0.1c3.2,0,6.4,0.8,9.4,2.2c0.3,0.2,0.7,0.4,1.2,0.6h2.1v-7.4
		c-4-1.8-8.4-2.7-12.8-2.5c-9.9,0-15.8,4.3-15.8,11.7c0,6.8,5.6,9.7,14,12c7,1.9,9,3.3,9,6c0,1.7-0.9,4.6-7.1,4.6
		c-3.7-0.1-7.4-1.1-10.7-3L5,138.2c-0.3-0.2-0.7-0.4-1.2-0.7H1.7v7.4c4.4,2.4,9.3,3.6,14.3,3.5c10,0,16-4.7,16-12.5
		C32,129.8,27.8,126.2,17.6,123.5"
      />
      <path
        d="M351.3,123.5c-6.4-1.7-8.6-2.7-8.6-5.4s2.4-4.2,6.8-4.2h0.1c3.2,0,6.4,0.8,9.3,2.2c0.3,0.2,0.7,0.4,1.2,0.6h2.1v-7.4
		c-4-1.8-8.4-2.7-12.8-2.5c-9.9,0-15.8,4.4-15.8,11.7c0,6.8,5.6,9.7,14,12c7,1.9,9,3.3,9,6c0,1.7-0.9,4.6-7.1,4.6
		c-3.7-0.1-7.4-1.1-10.7-3l-0.2-0.1c-0.3-0.2-0.7-0.4-1.2-0.7h-2.1v7.4c4.4,2.4,9.3,3.6,14.4,3.5c10,0,16-4.7,16-12.5
		C365.8,129.7,361.6,126.1,351.3,123.5"
      />
    </g>
  </svg>
);

export default SANDSLogo;

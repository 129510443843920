import * as React from 'react';

const EYLogo: React.FC = () => (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 560.39 573.03">
    <polygon points="560.39 0 0 204.53 560.39 105.54 560.39 0" />
    <path d="M89.92,461.06h102.5V401.54H89.92V354.76H203.3l-37.62-65.2H4.9V573H231.67v-65.2H89.92Zm292.67-171.5-48.15,92.51-48-92.51h-94l99,171.5V573h84.76v-112l99.17-171.5Z" />
  </svg>
);

export default EYLogo;

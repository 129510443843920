import * as React from 'react';

const ArntzenDeBesche: React.FC = () => (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 709.92 277.58">
    <g>
      <path d="M22.19,77H69.67l8.41,21.93H90L51,0H40.82L2.08,98.92h11.7ZM45.93,14.84,65.55,66.27H26.32Z" />
      <path d="M179.32,98.92h12v-2.8L169.43,64.46V63.8C181.3,59.35,189.22,49,189.22,33c0-21.43-13-33-34.46-33H119V98.92h11.54V66.11h25.72ZM130.52,55.4V10.71h24.07c13.85,0,22.75,7.26,22.75,22.59,0,15.17-8.9,22.1-21.76,22.1Z" />
      <polygon points="300.84 98.92 300.84 0 289.3 0 289.3 76.99 233.24 0 225.16 0 225.16 98.92 236.7 98.92 236.7 21.6 292.92 98.92 300.84 98.92" />
      <polygon points="332.84 10.71 362.35 10.71 362.35 98.92 373.89 98.92 373.89 10.71 403.56 10.71 403.56 0 332.84 0 332.84 10.71" />
      <polygon points="496.07 88.21 443.8 88.21 497.22 7.75 497.22 0 429.95 0 429.95 10.71 482.55 10.71 427.97 91.17 427.97 98.92 496.07 98.92 496.07 88.21" />
      <polygon points="594 88.21 541.9 88.21 541.9 54.24 586.25 54.24 586.25 43.52 541.9 43.52 541.9 10.71 594 10.71 594 0 530.36 0 530.36 98.92 594 98.92 594 88.21" />
      <polygon points="703.97 98.92 703.97 0 692.43 0 692.43 76.99 636.38 0 628.3 0 628.3 98.92 639.84 98.92 639.84 21.6 696.06 98.92 703.97 98.92" />
      <path d="M190.87,225.15v-.5c8.24-3.13,13.52-10.55,13.52-21.59,0-17.64-11.71-26.71-31-26.71H135v98.92H175c19,0,32.15-8.57,32.15-27.37C207.19,235.7,200.43,228.12,190.87,225.15Zm-44.35-38.09h26.71c12.2,0,19.29,4.62,19.29,16.49,0,10.06-7.58,16.32-19.29,16.32H146.52Zm28.85,77.5H146.52v-34h28.69c11,0,20.11,5.61,20.11,16.65C195.32,259.11,186.42,264.56,175.37,264.56Z" />
      <polygon points="241.99 275.27 305.63 275.27 305.63 264.56 253.53 264.56 253.53 230.59 297.88 230.59 297.88 219.87 253.53 219.87 253.53 187.06 305.63 187.06 305.63 176.35 241.99 176.35 241.99 275.27" />
      <path d="M369.27,218.89C350.48,213,346,208.83,346,200.75c0-10.22,8.41-15.66,20.28-15.66,15.66,0,23.58,8.41,23.58,8.41l6.27-9.57s-8.75-9.73-29.85-9.73c-20.11,0-32.15,10.39-32.15,26.55,0,15,8.25,22.09,29.84,29,19.3,6.1,23.25,10.71,23.25,19.95,0,10-6.43,17-21.43,17-16.66,0-25.56-9.56-25.56-9.56L334,265.87s9.89,11.71,32,11.71c21.77,0,33.14-12,33.14-28C399.11,234.88,393.18,226.14,369.27,218.89Z" />
      <path d="M471.66,185.09c14.18,0,23.09,10.71,23.09,10.71l6.59-8.9s-9.56-12.7-29.84-12.7c-26.87,0-45,22.59-45,51.77,0,28.86,18.14,51.61,45,51.61,20.28,0,29.84-12.7,29.84-12.7l-6.59-8.9s-8.91,10.72-23.09,10.72c-19.78,0-33.3-18.47-33.3-40.73C438.36,203.55,451.88,185.09,471.66,185.09Z" />
      <polygon points="594.5 221.19 544.88 221.19 544.88 176.35 533.34 176.35 533.34 275.27 544.88 275.27 544.88 231.91 594.5 231.91 594.5 275.27 606.04 275.27 606.04 176.35 594.5 176.35 594.5 221.19" />
      <polygon points="646.28 275.27 709.92 275.27 709.92 264.56 657.82 264.56 657.82 230.59 702.17 230.59 702.17 219.87 657.82 219.87 657.82 187.06 709.92 187.06 709.92 176.35 646.28 176.35 646.28 275.27" />
      <path d="M20.41,177.86H0V237.3H20.41c16.45,0,28.51-12,28.51-29.68S36.86,177.86,20.41,177.86Zm-.47,50.67H9.55V186.64H19.94c11.69,0,19.54,7.86,19.54,21S31.63,228.53,19.94,228.53Z" />
      <polygon points="65.28 237.3 104.01 237.3 104.01 228.59 74.75 228.59 74.75 211.57 99.63 211.57 99.63 202.87 74.75 202.87 74.75 186.64 104.01 186.64 104.01 177.86 65.28 177.86 65.28 237.3" />
    </g>
  </svg>
);

export default ArntzenDeBesche;

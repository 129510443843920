import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { listWithoutStyle } from 'styleMixins/listMixins';
import createMediaQuery from 'util/createMediaQuery';
import Image from 'components/Image';
import EYLogo from 'components/customerLogos/EY';
import ThommessenLogo from 'components/customerLogos/Thommessen';
import ArntzenDeBesche from 'components/customerLogos/ArntzenDeBesche';
import FoyenTorkildsenLogo from 'components/customerLogos/FoyenTorkildsen';
import PwCLogo from 'components/customerLogos/PwC';
import Kluge from 'components/customerLogos/CMSKluge';
import SkatteetatenLogo from 'components/customerLogos/Skatteetaten';
import SimonsenVogtWiigLogo from 'components/customerLogos/SimonsenVogtWiig';
import SANDSLogo from 'components/customerLogos/SANDS';
import SelmerLogo from 'components/customerLogos/Selmer';
import RaederLogo from 'components/customerLogos/Ræder';
import EurojurisLogo from 'components/customerLogos/Eurojuris';
import HaavindLogo from 'components/customerLogos/Haavind';
import MediaWrapper from '../../../components/MediaWrapper';

const CustomerSectionCss = styled.section`
  background: ${({ theme }) => theme.colorTokens.default.white};
  padding: 48px 25px;

  color: ${({ theme }) => theme.colorTokens.default.black};

  > div {
    max-width: 50rem;
    margin: 25px auto;

    ${createMediaQuery(
      'md',
      css`
        max-width: 90rem;
        padding-right: 45px;
        padding-left: 45px;
      `
    )}
  }
`;

const TopCss = styled.div`
  margin-bottom: 4.8125rem;
  text-align: center;
`;

const TitleCss = styled.h2`
  margin: 0 0 1.125rem;
  color: ${({ theme }) => theme.colorTokens.red[600]};
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-size: 2.625rem;
  letter-spacing: 0.0519rem;

  ${createMediaQuery(
    'md',
    css`
      font-size: 3.125rem;
    `
  )}
`;

const TextCss = styled.div`
  color: ${({ theme }) => theme.colorTokens.default.black};
  font-size: 1.375rem;
  letter-spacing: 0.0231rem;
`;

const LogosWrapperCss = styled.div`
  overflow: hidden;
`;

const LogosListCss = styled.ul`
  ${listWithoutStyle}

  margin: -2.5rem 0 0 -4.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  ${createMediaQuery(
    'md',
    css`
      margin: -5rem 0 0 -4.375rem;
      justify-content: flex-start;
    `
  )}
`;

const handleLogoStyle = (name: string) => {
  switch (name) {
    case 'eyLogo':
      return '4.3125rem';
    case 'thommessenLogo':
      return '15.0625rem';
    case 'wiersholmLogo':
      return '12.0625rem';
    case 'arntzenDeBescheLogo':
      return '9.8125rem';
    case 'foyenTorkildsenLogo':
      return '13.75rem';
    case 'klugeLogo':
      return '12.5rem';
    case 'pwCLogo':
      return '4.375rem';
    case 'gretteLogo':
      return '8.125rem';
    case 'skatteetatenLogo':
      return '13.125rem';
    case 'simonsenVogtwiigLogo':
      return '10.4375rem';
    case 'saandsLogo':
      return '6.25rem';
    case 'selmerLogo':
      return '13rem';
    case 'raederLogo':
      return '8.375rem';
    case 'hjortLogo':
      return '10.4375rem';
    case 'eurojurisLogo':
      return '15.8125rem';
    case 'haavindLogo':
      return '13.75rem';
    case 'BAHRLogo':
      return '10.4375rem';
    case 'BraekhusLogo':
      return '10.4375rem';

    default:
      return '0';
  }
};

const LogosItemCss = styled.li<{ name: string }>`
  margin: 2.5rem 0 0 4.375rem;

  ${createMediaQuery(
    'md',
    css`
      margin: 5rem 0 0 4.375rem;
      justify-content: flex-start;
    `
  )}

  width: ${({ name }) => handleLogoStyle(name)};
`;

const CustomersSection: FC = () => (
  <CustomerSectionCss>
    <div>
      <TopCss>
        <TitleCss>Du er i godt selskap</TitleCss>
        <TextCss>Norges sterkeste fagmiljøer bruker Juridika i sitt juridiske kildearbeid.</TextCss>
      </TopCss>
      <LogosWrapperCss>
        <LogosListCss>
          <LogosItemCss name="eyLogo">
            <EYLogo />
          </LogosItemCss>
          <LogosItemCss name="thommessenLogo">
            <ThommessenLogo />
          </LogosItemCss>
          <LogosItemCss name="wiersholmLogo">
            <MediaWrapper>
              <Image src="https://dfwibl8ld7pfq.cloudfront.net/image/customer-logos/Wiersholm.png" />
            </MediaWrapper>
          </LogosItemCss>
          <LogosItemCss name="arntzenDeBescheLogo">
            <ArntzenDeBesche />
          </LogosItemCss>
          <LogosItemCss name="foyenTorkildsenLogo">
            <FoyenTorkildsenLogo />
          </LogosItemCss>
          <LogosItemCss name="klugeLogo">
            <MediaWrapper>
              <Kluge />
            </MediaWrapper>
          </LogosItemCss>
          <LogosItemCss name="pwCLogo">
            <PwCLogo />
          </LogosItemCss>
          <LogosItemCss name="gretteLogo">
            <MediaWrapper>
              <Image src="https://dfwibl8ld7pfq.cloudfront.net/image/customer-logos/Grette.png" />
            </MediaWrapper>
          </LogosItemCss>
          <LogosItemCss name="skatteetatenLogo">
            <SkatteetatenLogo />
          </LogosItemCss>
          <LogosItemCss name="simonsenVogtwiigLogo">
            <SimonsenVogtWiigLogo />
          </LogosItemCss>
          <LogosItemCss name="saandsLogo">
            <SANDSLogo />
          </LogosItemCss>
          <LogosItemCss name="selmerLogo">
            <SelmerLogo />
          </LogosItemCss>
          <LogosItemCss name="raederLogo">
            <RaederLogo />
          </LogosItemCss>
          <LogosItemCss name="hjortLogo">
            <Image src="https://dfwibl8ld7pfq.cloudfront.net/image/customer-logos/HJORT.png" />
          </LogosItemCss>
          <LogosItemCss name="eurojurisLogo">
            <EurojurisLogo />
          </LogosItemCss>
          <LogosItemCss name="haavindLogo">
            <HaavindLogo />
          </LogosItemCss>
          <LogosItemCss name="BAHRLogo">
            <MediaWrapper>
              <Image src="https://dfwibl8ld7pfq.cloudfront.net/image/customer-logos/BAHR.png" />
            </MediaWrapper>
          </LogosItemCss>
          <LogosItemCss name="BraekhusLogo">
            <MediaWrapper>
              <Image src="https://dfwibl8ld7pfq.cloudfront.net/image/customer-logos/Brækhus.png" />
            </MediaWrapper>
          </LogosItemCss>
        </LogosListCss>
      </LogosWrapperCss>
    </div>
  </CustomerSectionCss>
);

export default CustomersSection;

import * as React from 'react';

const FoyenTorkildsenLogo: React.FC = () => (
  <svg viewBox="0 0 506 83.04" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_0_3)">
      <path d="M0 83.04H17.24V47.6H64.64V35.21H17.24V12.96H72.76V0H0V83.04Z" fill="black" />
      <path d="M319.1 0V83.04H391.97V70.08H336.34V45.99H383.86V33.6H336.34V12.96H391.97V0H319.1Z" fill="black" />
      <path
        d="M489.59 -0.0400009C489.59 -0.0400009 489.59 52.14 489.86 59.96L489.59 60.08C483.89 53.43 478.19 46.89 472.08 40.01L436.52 -0.0400009H419.69V82.96H436.93V48.17C436.93 39.34 436.93 30.85 436.66 23.05L436.93 22.94C442.63 29.59 448.33 36.13 454.44 43.01L490 83.01H506.83V-0.0400009H489.59Z"
        fill="black"
      />
      <path
        d="M282.15 -0.0400009C282.15 -0.0400009 254.39 25.21 250.59 30.15H250.51C246.71 25.25 218.96 -0.0400009 218.96 -0.0400009H202.56V4.34L241.83 42.53V83H259.3V42.57L298.57 4.38V-0.0400009H282.15Z"
        fill="black"
      />
      <path
        d="M162.3 7.22C154.618 3.47066 146.168 1.56354 137.62 1.65C110.69 1.65 90.45 18.97 90.45 41.52C90.4254 51.0147 94.1106 60.1434 100.72 66.96L88.59 78.7V83.09H104.98C104.98 83.09 108.35 80.04 112.9 75.87C120.583 79.6159 129.033 81.5196 137.58 81.43C164.52 81.43 184.75 64.12 184.75 41.57C184.748 32.0845 181.05 22.9732 174.44 16.17L186.57 4.43V-0.0400009H170.18C170.18 -0.0400009 166.81 3.04 162.3 7.22ZM168.73 41.52C168.73 56.91 155.45 68.98 137.62 68.98C119.79 68.98 106.51 56.91 106.51 41.52C106.51 26.13 119.79 14.05 137.62 14.05C155.45 14.05 168.73 26.13 168.73 41.52Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_0_3">
        <rect width="506.83" height="83.04" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default FoyenTorkildsenLogo;

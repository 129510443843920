import * as React from 'react';

const SimonsenVogtWiigLogo: React.FC = () => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 551.6 196.8">
    <g>
      <path
        d="M179.5,0.1c-2.6,0-5.3,0.3-7.8,1.1c-2.1,0.6-4.1,1.5-5.9,2.7c-1.6,1.1-3.2,2.3-4.6,3.7c-1.4,1.2-3,2.9-4.2,4
		c-0.7-2-1.7-3.8-3-5.5c-3.1-4-7.9-6-14.2-6c-2.6,0-5.3,0.3-7.8,1.1c-2.1,0.6-4.1,1.5-5.9,2.7c-1.6,1.1-3.2,2.3-4.6,3.7
		c-1.5,1.4-4,3.9-5.2,5.1V2H93.9v2.6c1.2,0.1,2.3,0.2,3.5,0.5c1.1,0.2,2.2,0.7,3.2,1.3c1.2,0.8,2.2,2,2.9,3.3c0.7,1.4,1,2.9,1,4.4
		v56.5h11.8V18c5.3-6.3,10.3-10.5,15.8-11c0.4,0,0.8-0.1,1.2-0.1c3.7,0,7.1,1.7,9.4,4.6c2.2,2.5,3.4,6.5,3.4,11.4v47.5h12.7V14.6
		c4.1-4.4,8.2-7.2,13.1-7.6c0.4,0,0.8-0.1,1.2-0.1c3.7,0,7.1,1.7,9.4,4.6c2.2,2.5,3.3,6.4,3.3,11.4v47.5h12.7V22
		c0-6.6-1.6-12-4.6-15.9S185.9,0.1,179.5,0.1"
      />
      <path
        d="M56.7,4.7c1.4,0.1,2.8,0.4,4.2,0.7c1.3,0.3,2.6,0.8,3.8,1.4c1.2,0.8,2.1,1.9,2.8,3.2c0.7,1.3,1,2.7,1,4.1v56.3h11.9V2H56.7
		V4.7z"
      />
      <path
        d="M412.6,107.7c1.4,0.1,2.8,0.4,4.2,0.7c1.3,0.3,2.6,0.8,3.8,1.4c1.2,0.8,2.1,1.9,2.8,3.1c0.7,1.3,1,2.7,1,4.1v56.4h11.8V105
		h-23.7V107.7z"
      />
      <path
        d="M450.3,107.7c1.4,0.1,2.8,0.4,4.2,0.7c1.3,0.3,2.6,0.8,3.8,1.4c1.2,0.8,2.1,1.9,2.8,3.1c0.7,1.3,1,2.7,1,4.1v56.4H474V105
		h-23.7V107.7z"
      />
      <path
        d="M244.3,0c-10,0-18.2,3.4-24.4,10.2s-9.3,15.6-9.3,26.2c-0.1,5.1,0.8,10.2,2.7,15c1.6,4.2,4,8.1,7.1,11.3
		c3,3.1,6.5,5.5,10.4,7.1c3.9,1.6,8.1,2.5,12.4,2.5c4.8,0.1,9.6-0.9,14.1-2.7c4.3-1.9,8.1-4.7,11.1-8.2c2.9-3.4,5.2-7.4,6.5-11.8
		c1.5-4.6,2.3-9.4,2.3-14.2c0-10.7-3.1-19.3-9.3-25.7S253.8,0,244.3,0 M258.6,59.7c-3.5,5.9-8.3,8.9-14.3,8.9
		c-3.3,0.1-6.5-0.8-9.2-2.7c-2.6-2-4.7-4.5-6.1-7.4c-1.7-3.4-2.9-6.9-3.5-10.6c-0.8-4.3-1.1-8.6-1.1-12.9c0-3.7,0.3-7.4,0.9-11
		c0.5-3.5,1.6-7,3.2-10.2c1.5-3,3.6-5.7,6.4-7.7c2.8-1.9,6.1-2.9,9.5-2.8c3.3-0.1,6.5,0.9,9.2,2.8c2.6,1.9,4.6,4.4,6,7.3
		c1.6,3.3,2.7,6.8,3.2,10.4c0.7,3.8,1.1,7.7,1.1,11.5C263.8,45.6,262.1,53.9,258.6,59.7"
      />
      <path
        d="M474.2,8.9c-2.3-2.8-5.3-5-8.7-6.5c-4.1-1.7-8.4-2.5-12.9-2.4c-4.6-0.1-9.1,1-13.2,3c-3.8,1.9-7.2,4.7-9.8,8
		c-2.7,3.4-4.8,7.4-6.2,11.5c-1.4,4.2-2.1,8.7-2.1,13.1c0,4.8,0.7,9.5,2,14.1c1.2,4.3,3.3,8.2,6,11.7c2.8,3.4,6.2,6.1,10.2,7.9
		c4.6,2,9.5,3,14.5,2.9c5.9,0.1,11.7-1.6,16.6-4.8c0.2-0.2,0.5-0.3,0.7-0.5l-1.5-3c-2.5,1.5-5.2,2.5-8.1,2.8
		c-13,1.5-25-10.1-26.9-26c-0.2-1.3-0.2-2.6-0.2-3.8l0,0V34h45.9v-4.5c0-3.9-0.5-7.8-1.6-11.5C478.1,14.7,476.4,11.5,474.2,8.9
		 M467.8,30.3h-33.2v-0.4c0-0.1,0-2.8,0-2.9c0.3-7,2.2-12.5,5.4-16.5c3-3.9,7.7-6.2,12.7-6.2c2.7-0.1,5.3,0.6,7.5,2.1
		c2,1.4,3.6,3.2,4.7,5.3c1.1,2.4,1.9,4.9,2.2,7.5c0.4,2.5,0.6,5.1,0.7,7.7L467.8,30.3z"
      />
      <path
        d="M547,6c-3.1-4-7.8-6-14.2-6c-2.6,0-5.3,0.3-7.8,1.1c-2.1,0.6-4.1,1.5-5.9,2.7c-1.6,1.1-3.2,2.3-4.6,3.7
		c-1.5,1.4-4,3.9-5.2,5.1l0-1.6V2h-22.4v2.5c1.2,0.1,2.4,0.2,3.5,0.5c1.1,0.2,2.2,0.6,3.1,1.2c1.2,0.8,2.2,1.9,2.9,3.2
		c0.7,1.4,1,2.9,1,4.4v56.6h11.8V18c5.3-6.3,10.3-10.6,15.8-11c0.4,0,0.8-0.1,1.2-0.1c3.7,0,7.1,1.7,9.3,4.6
		c2.2,2.5,3.3,6.4,3.3,11.4v47.5h12.7V21.9C551.6,15.3,550.1,10,547,6"
      />
      <path
        d="M406.2,37.8c-2-1.7-4.2-3.2-6.6-4.2c-2.8-1.3-5.7-2.5-8.6-3.4c-3-1-5.7-1.9-7.9-2.8c-1.9-0.7-3.7-1.6-5.4-2.7
		c-1.4-1-2.6-2.2-3.4-3.8c-0.8-1.7-1.2-3.6-1.2-5.5c-0.1-2,0.5-3.9,1.5-5.6c0.6-1,1.4-1.9,2.3-2.6c2.8-2.1,6.2-3.3,9.7-3.5h0.7l0,0
		c3,0,5.9,0.5,8.8,1.3c2,0.6,3.9,1.4,5.8,2.4c0.5-0.9,1-1.7,1.4-2.6h-0.1c-4.9-3.3-10.7-5.1-16.6-5c-0.6,0-1.2,0-1.8,0
		c-5.7,0.3-11.1,2.5-15.3,6.3c-3.9,3.6-6.1,8.7-6,14.1c-0.1,2.8,0.6,5.6,1.9,8.1c1.2,2.3,2.9,4.3,4.9,5.9c2.1,1.6,4.4,3,6.9,4
		c2.4,1,4.8,1.9,7.3,2.7c2.7,0.8,5.3,1.7,7.5,2.6c2,0.8,4,1.8,5.8,3c1.5,1,2.7,2.4,3.6,3.9c0.8,1.6,1.3,3.5,1.2,5.3
		c0,4-1.3,7-3.8,9.2c-2.5,2.2-6.4,3.2-11.2,3.2c-3.1,0-6.1-0.7-8.8-2.2c-2.5-1.4-4.8-3.2-6.7-5.3c-1.8-2.1-3.3-4.3-4.5-6.8
		c-0.9-1.8-1.7-3.7-2.2-5.6L365,48h-2v22.4h2.2l1.6-5.2c0,0,6.9,7.2,19.3,7.2l0,0h0.1c7.6,0,14.1-2.1,18.7-6c4.6-3.7,7.1-9.4,7-15.2
		c0.1-2.7-0.4-5.4-1.5-7.8C409.5,41.2,408,39.3,406.2,37.8"
      />
      <path
        d="M43.2,37.8c-2-1.7-4.2-3.2-6.6-4.2c-2.8-1.3-5.6-2.5-8.6-3.4c-3-1-5.7-1.9-7.9-2.8c-1.9-0.7-3.7-1.6-5.4-2.7
		c-1.4-0.9-2.6-2.2-3.4-3.8c-0.9-1.7-1.3-3.6-1.3-5.5c-0.1-2,0.5-3.9,1.5-5.6c0.6-1,1.4-1.9,2.3-2.6C16.6,5.2,20,4,23.5,3.8h0.7l0,0
		c3,0,5.9,0.5,8.8,1.4c2,0.6,3.9,1.4,5.8,2.4c0.5-0.9,1-1.7,1.5-2.6l0,0c-4.9-3.3-10.7-5.1-16.6-5c-0.6,0-1.2,0-1.8,0
		C16,0.3,10.6,2.5,6.4,6.4c-3.9,3.6-6.1,8.7-6,14.1c-0.1,2.8,0.6,5.6,1.9,8.1c1.2,2.3,2.9,4.3,4.9,5.9c2.1,1.6,4.4,2.9,6.8,3.9
		c2.4,1,4.8,1.9,7.3,2.7c2.8,0.8,5.3,1.7,7.5,2.6c2,0.8,4,1.8,5.8,3c1.5,1,2.7,2.4,3.6,3.9c0.8,1.6,1.3,3.5,1.2,5.3
		c0,4-1.3,7-3.8,9.2c-2.5,2.2-6.4,3.2-11.2,3.2c-3.1,0-6.1-0.7-8.8-2.2c-2.5-1.4-4.8-3.2-6.7-5.3C7,58.7,5.5,56.4,4.3,54
		c-0.9-1.8-1.7-3.7-2.2-5.6L2,48H0v22.4h2.2l1.6-5.2c0,0,6.9,7.2,19.3,7.2l0,0h0.1c7.6,0,14.1-2.1,18.7-6c4.6-3.7,7.1-9.4,7-15.2
		c0.1-2.7-0.4-5.4-1.5-7.8C46.5,41.3,45,39.3,43.2,37.8"
      />
      <path
        d="M328.5,0c-2.6,0-5.2,0.3-7.8,1.1c-2.1,0.6-4.1,1.5-6,2.7c-1.6,1.1-3.2,2.3-4.6,3.7c-1.5,1.4-4,3.9-5.2,5.1L305,11V2h-22.4
		v2.5c1.2,0.1,2.3,0.2,3.5,0.5c1.1,0.2,2.2,0.6,3.2,1.2c1.2,0.8,2.2,1.9,2.9,3.2c0.7,1.4,1,2.9,1,4.4v56.6H305V18
		c5.2-6.3,10.3-10.6,15.8-11c0.4,0,0.8-0.1,1.2-0.1c3.7,0,7.1,1.7,9.3,4.6c2.2,2.5,3.4,6.4,3.4,11.4v47.5h12.7V21.9
		c0-6.6-1.5-11.9-4.6-15.9S334.9,0,328.5,0"
      />
      <path
        d="M147.8,102.3c-10,0-18.2,3.4-24.4,10.2s-9.3,15.6-9.3,26.2c-0.1,5.1,0.8,10.2,2.7,15c1.6,4.2,4.1,8.1,7.2,11.4
		c3,3.1,6.5,5.5,10.4,7.2c3.9,1.6,8.1,2.5,12.4,2.4c4.8,0.1,9.7-0.9,14.1-2.7c4.2-1.9,8-4.7,11-8.2c2.9-3.5,5.2-7.5,6.5-11.8
		c1.5-4.6,2.3-9.4,2.3-14.2c0-10.7-3.1-19.3-9.3-25.7S157.3,102.3,147.8,102.3 M162.1,162c-3.5,5.9-8.3,8.9-14.3,8.9
		c-3.3,0.1-6.5-0.8-9.2-2.7c-2.6-2-4.7-4.5-6.1-7.4c-1.7-3.4-2.9-6.9-3.5-10.7c-0.8-4.3-1.1-8.6-1.1-12.9c0-3.7,0.3-7.4,0.9-11
		c0.5-3.5,1.6-7,3.2-10.2c1.5-3,3.7-5.7,6.4-7.7c2.8-1.9,6.1-2.9,9.5-2.8c3.3-0.1,6.5,0.9,9.2,2.8c2.6,1.9,4.6,4.4,6,7.3
		c1.6,3.3,2.7,6.8,3.2,10.4c0.7,3.8,1,7.7,1.1,11.5C167.3,147.9,165.6,156.1,162.1,162"
      />
      <path
        d="M297.6,170.7l-0.1,3.4c-2.7,0.2-5.4,0.2-8.1,0c-5.1-0.3-9.1-1.3-11.2-4s-3.3-6.5-3.3-11.3V108h-7.5v-3h7.8v-9.8h11.7v9.8
		h12.4v3H287v47.2c0,2.2,0.1,4.4,0.3,6.6c0.8,5,5.1,8.8,10.2,8.9C297.6,170.7,297.6,170.7,297.6,170.7"
      />
      <path
        d="M91.7,105v2.8h2.1c3.1,0,4.3,2.8,3.1,7.4c-1.7,6.8-17.1,43.5-17.1,43.5c-1.9-3.6-15.9-44.6-15.9-47.7
		c-0.2-1.6,1.1-3.1,2.7-3.3c0.1,0,0.3,0,0.4,0h2.9V105H42.7v2.8h2.4c3.1,0,4.8,1.4,7.1,6.4c1.2,2.8,17.3,47.5,21.8,59.2h5.7
		c4.9-11.1,14-32.2,15.5-36c2.6-6.4,10.9-25.2,13.5-27.7c1.4-1.4,3.4-2.1,5.4-1.9h2.1V105H91.7z"
      />
      <path
        d="M243.4,105v5.7c-5.8-5.6-13-8.4-21.7-8.4c-9.5,0-17.3,3.2-23.1,9.6s-8.8,14.8-8.8,24.8c-0.1,4.9,0.8,9.7,2.6,14.2
		c1.5,4,3.8,7.6,6.7,10.8c2.8,2.9,6.2,5.2,9.9,6.8c3.7,1.5,7.7,2.3,11.8,2.3c4.6,0.1,9.2-0.8,13.4-2.6c3.5-1.5,6.6-3.7,9.2-6.5v1.8
		c0,0,0,1.3,0,4.3c0,8.7-1.2,14.2-4.9,17.9c-3.4,3.6-8.1,5.8-13.1,6.1c-6.1,0.7-14.2-2.4-14.2-2.4l-2.7,3.6c1.7,0.9,3.5,1.6,5.3,2.1
		c3.1,0.9,6.3,1.4,9.6,1.5c7.8-0.2,16.3-2.2,22.4-7.7c6.1-5.7,9.2-14.7,9.2-29.6V105H243.4z M237.9,158.2c-3.6,5.5-8.5,8.2-14.6,8.2
		c-3.3,0.1-6.6-0.8-9.4-2.5c-2.6-1.8-4.7-4.1-6.3-6.8c-1.7-3-2.9-6.3-3.6-9.8c-0.8-3.9-1.2-7.9-1.1-11.9c0-3.4,0.3-6.8,0.9-10.1
		c0.5-3.3,1.6-6.4,3.2-9.3c1.6-2.8,3.8-5.2,6.5-7c2.9-1.8,6.3-2.7,9.7-2.6c3.3-0.1,6.6,0.8,9.4,2.6c2.6,1.7,4.7,4,6.2,6.7
		c1.6,3,2.7,6.2,3.3,9.5c0.7,3.5,1.1,7.1,1.1,10.6C243.2,145.3,241.4,152.8,237.9,158.2"
      />
      <path
        d="M540,105v5.7c-5.8-5.6-13-8.4-21.7-8.4c-9.5,0-17.3,3.2-23.1,9.6s-8.8,14.8-8.8,24.8c-0.1,4.9,0.8,9.7,2.6,14.2
		c1.5,4,3.8,7.6,6.8,10.7c2.8,2.9,6.2,5.2,9.9,6.8c3.7,1.5,7.7,2.3,11.7,2.3c4.6,0.1,9.2-0.8,13.4-2.6c3.5-1.5,6.6-3.7,9.2-6.5v1.8
		c0,0,0,1.3,0,4.3c0,8.7-1.2,14.2-4.9,17.9c-3.4,3.6-8.1,5.8-13.1,6.1c-6.1,0.7-14.2-2.4-14.2-2.4l-2.7,3.6c1.7,0.9,3.5,1.6,5.3,2.1
		c3.1,0.9,6.3,1.4,9.5,1.5c7.8-0.2,16.3-2.2,22.4-7.7c6.1-5.7,9.2-14.7,9.2-29.6V105H540z M534.6,158.2c-3.6,5.5-8.5,8.2-14.7,8.2
		c-3.3,0.1-6.6-0.8-9.4-2.5c-2.6-1.8-4.8-4.1-6.3-6.8c-1.7-3-2.9-6.3-3.6-9.8c-0.8-3.9-1.2-7.9-1.1-11.9c0-3.4,0.3-6.8,1-10.1
		c0.6-3.3,1.7-6.4,3.3-9.3c1.6-2.8,3.8-5.2,6.5-7c2.9-1.8,6.3-2.7,9.7-2.6c3.3-0.1,6.6,0.8,9.4,2.6c2.6,1.7,4.7,4,6.2,6.7
		c1.6,3,2.7,6.2,3.3,9.5c0.7,3.5,1.1,7.1,1.1,10.6C539.9,145.3,538.1,152.8,534.6,158.2"
      />
      <path
        d="M402.7,108.8l0.2-0.1c1.6-0.7,3.2-1,4.9-1V105h-23.9v3h3.7c3.7,0,5.2,3.8,2.8,10.2l-11.4,40.9l-16.7-51.2h-6.5l-17.4,54.3
		L324.1,113c-1.1-3.3,0.2-5,2.2-5h3.1v-3h-26.2v3h1.4c4.4,0,6.5,1.5,7.7,5l18,60.2H341l14.7-47.7h0.9l15.9,47.7h7.7
		c6.2-15.4,16.1-52.6,19.1-60.8l0,0C399.9,110.8,401.1,109.5,402.7,108.8"
      />
    </g>
  </svg>
);

export default SimonsenVogtWiigLogo;

import * as React from 'react';
import styled, { css } from 'styled-components';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import createFontStyles from 'util/createFontStyles';
import createMediaQuery from 'util/createMediaQuery';

interface CardHeaderProps {
  margin?: string;
  fontSize?: number;
  padding?: string;
  textAlign?: 'left' | 'center' | 'right';
  minHeight?: string | number;
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}
interface CardBodyProps {
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  padding?: string;
  margin?: string;
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}

interface CardFooterProps {
  alignRight?: boolean;
  padding?: string;
  margin?: string;
  className?: string;
  backgroundColor?: string;
  borderColor?: string;
  children: React.ReactNode | React.ReactNode[];
}

interface CardProps {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
}

const ContainerCss = styled.div`
  background-color: ${({ theme }) => theme.colorTokens.default.white};
  border-radius: 6px;
  border: 1px solid ${COLOR_TOKENS.grey[600]};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const HeaderCss = styled.h4<CardHeaderProps>`
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '0.625rem'};
  text-align: ${({ textAlign }) => textAlign || 'left'};

  ${(props) =>
    createFontStyles({
      family: props.theme.fontFamilies.secondary,
      size: props.fontSize || 1.125,
      weight: 600,
    })};

  ${({ minHeight }) =>
    minHeight &&
    css`
      ${createMediaQuery(
        576,
        css`
          min-height: ${minHeight};
        `
      )}

      ${createMediaQuery(
        768,
        css`
          min-height: 0;
        `
      )}

      ${createMediaQuery(
        'md',
        css`
          min-height: ${minHeight};
        `
      )}
    `}
`;

const BodyCss = styled.div<CardBodyProps>`
  display: flex;
  flex-grow: 1;
  color: ${COLOR_TOKENS.grey[800]};
  flex-direction: ${(props) => (props.flexDirection ? props.flexDirection : 'row')};
  justify-content: space-between;
  padding: ${({ padding }) => padding || '0.625rem'};
  padding-top: 0;
`;

const FooterCss = styled.div<CardFooterProps>`
  display: flex;
  padding: ${({ padding }) => padding || '0.625rem'};
  background-color: ${({ backgroundColor }) => backgroundColor || COLOR_TOKENS.grey[100]};
  border-top: 1px solid ${({ borderColor }) => borderColor || COLOR_TOKENS.grey[600]};
  justify-content: ${(props) => (props.alignRight ? 'flex-end' : 'flex-start')};
  align-items: center;
`;

export const Card: React.FC<CardProps> = ({ children, className = '' }) => (
  <ContainerCss className={className}>{children}</ContainerCss>
);

export const CardHeader: React.FC<CardHeaderProps> = ({
  children,
  className,
  margin,
  padding,
  fontSize,
  textAlign,
  minHeight,
}) => (
  <HeaderCss
    margin={margin}
    fontSize={fontSize}
    padding={padding}
    minHeight={minHeight}
    textAlign={textAlign}
    className={className}
  >
    {children}
  </HeaderCss>
);

export const CardBody: React.FC<CardBodyProps> = ({ flexDirection, margin, padding, children, className }) => (
  <BodyCss flexDirection={flexDirection} margin={margin} padding={padding} className={className}>
    {children}
  </BodyCss>
);

export const CardFooter: React.FC<CardFooterProps> = ({
  alignRight,
  backgroundColor,
  padding,
  margin,
  className = '',
  children,
}) => {
  return (
    <FooterCss backgroundColor={backgroundColor} alignRight={alignRight} margin={margin} padding={padding} className={className}>
      {children}
    </FooterCss>
  );
};

import React from 'react';
import styled from 'styled-components';
import URI from 'urijs';
import BreadcrumbsItem, { Breadcrumb } from './BreadcrumbsItem';

const BreadcrumbsCss = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;

  display: flex;
  flex-wrap: wrap;
  align-item: center;

  @media print {
    display: none;
  }
`;

// Potentially relative breadcrumb
export interface RelativeBreadcrumb {
  // The displayed name for the breadcrumb
  name: string | JSX.Element;
  // An absolute URL
  url?: URI;
  // A path segment relative to the previous breadcrumb
  segment?: string;
}

const buildBreadcrumbs = (urlBuilder: URI, relativeBreadcrumbs: Array<RelativeBreadcrumb>): Array<Breadcrumb> => {
  return relativeBreadcrumbs.reduce((agg, { name, url, segment }) => {
    if (url) {
      // Use the explicit url as the new url builder.
      urlBuilder = url.clone();
    } else if (segment) {
      // Add a new segment
      urlBuilder.segment(segment);
    } else {
      return agg;
    }

    return [
      ...agg,
      {
        to: urlBuilder.toString(),
        name,
      },
    ];
  }, [] as Array<Breadcrumb>);
};

const Breadcrumbs: React.FC<{
  addHomePath?: boolean;
  path: Array<RelativeBreadcrumb>;
  ref?: React.Ref<HTMLUListElement>;
}> = React.forwardRef(({ addHomePath = true, path }, ref) => (
  <BreadcrumbsCss ref={ref}>
    {[
      ...(addHomePath
        ? [
            {
              to: '/',
              name: 'Hjem',
            },
          ]
        : []),
      ...buildBreadcrumbs(URI('/'), path),
    ].map((crumb) => {
      return <BreadcrumbsItem key={crumb.to} breadcrumb={crumb} />;
    })}
  </BreadcrumbsCss>
));

export default Breadcrumbs;

import { Card } from 'components/Card/Card';
import Flex from 'components/ui/Flex';
import React, { FC } from 'react';
import { linkWithoutStyle } from 'styleMixins/listMixins';
import styled, { css } from 'styled-components';
import createMediaQuery from 'util/createMediaQuery';

export const LOCATION_STATE_KEY_SELECTED_PACKAGE_NAME_ON_ABOUT_PAGE = 'selectedPackageNameOnAboutPage';

const SectionCss = styled.section`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
  padding: 48px 0;
`;

const GridWrapperCss = styled.div`
  margin-bottom: 1rem;
  display: grid;
  grid-auto-rows: min-content;

  > div {
    margin-bottom: 1rem;
  }

  ${createMediaQuery(
    600,
    css`
      grid-auto-rows: 1fr;
    `
  )}

  ${createMediaQuery(
    920,
    css`
      margin: auto;
      display: grid;
      justify-items: center;
      grid-auto-rows: min-content;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    `
  )}

  ${createMediaQuery(
    'mlg',
    css`
      display: grid;
      justify-items: center;
      grid-template-columns: repeat(2, 1fr);
    `
  )}
`;

const TitleCss = styled.h2`
  text-align: center;
  margin-top: 0;
  margin-bottom: 2.1875rem;
  padding: 0 1rem;
  font-weight: 500;
  font-family: ${(props) => props.theme.fontFamilies.primary};
  font-size: 1.75rem;

  ${createMediaQuery(
    'md',
    css`
      font-size: 3.125rem;
    `
  )}
`;

const GridCardCss = styled(Card)`
  border: 1px solid #e5dbd5;
  padding: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${createMediaQuery(
    'md',
    css`
      max-width: 700px;
    `
  )}
`;

const ImageCss = styled.img`
  max-width: 75%;
  max-height: 100%;
  margin: auto;
`;

const GetStartedLinkCss = styled.a`
  ${linkWithoutStyle}
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colorTokens.purple[700]};
  padding: 20px 30px;
  color: ${({ theme }) => theme.colorTokens.default.white};
  border-radius: 4px;
  display: inline-block;

  ${createMediaQuery(
    'md',
    css`
      margin-top: 3.5rem;
    `
  )}
`;

const IdealUserProfileSection: FC = () => {
  return (
    <SectionCss>
      <TitleCss>Juridika passer perfekt for:</TitleCss>
      <GridWrapperCss>
        {Array.from([
          {
            id: '1',
            text: 'Deg som er advokat, og som trenger hjelp med å underbygge argumentene dine raskt og effektivt for å ha en ekstra solid sak.',
            imageSrc: 'https://dfwibl8ld7pfq.cloudfront.net/image/illustrations/pakke-familierett.png',
            imageAlt: 'Illustrasjon av en familie',
          },
          {
            id: '2',
            text: 'Deg som er leder, og som trenger å holde deg raskt oppdatert på jussen for å gjøre ting riktig og i rett tid.',
            imageSrc: 'https://dfwibl8ld7pfq.cloudfront.net/image/illustrations/pakke-arbeidsliv.png',
            imageAlt: 'Illustrasjon av personer som arbeider',
          },
          {
            id: '3',
            text: 'Deg som er jurist i et selskap, og som trenger effektiv og grundig problemløsning.',
            imageSrc: 'https://dfwibl8ld7pfq.cloudfront.net/image/illustrations/pakke-offentlig-styring.png',
            imageAlt: 'Illustrasjon av folk som lærer og underviser',
          },
          {
            id: '4',
            text: 'Deg som trenger å ha enkel tilgang til gode og oppdaterte jusskilder til enhver tid.',
            imageSrc: 'https://dfwibl8ld7pfq.cloudfront.net/image/illustrations/pakke-kontrakter.png',
            imageAlt: 'Illustrasjon av folk som bearbeider kontrakter',
          },
        ]).map((card: { id: string; text: string; imageSrc: string; imageAlt: string }) => (
          <GridCardCss key={card.id}>
            <Flex justify="center">
              <ImageCss src={card.imageSrc} alt={card.imageAlt} />
            </Flex>
            <p>{card.text}</p>
          </GridCardCss>
        ))}
      </GridWrapperCss>
      <Flex align="center">
        <GetStartedLinkCss href="/priser">SE PRISER OG PAKKER</GetStartedLinkCss>
      </Flex>
    </SectionCss>
  );
};

export default IdealUserProfileSection;

import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import createMediaQuery from 'util/createMediaQuery';
import WidthConstraints from 'components/WidthConstraints';
import { linkWithoutStyle } from 'styleMixins/listMixins';

const TryJuridikaSectionCss = styled.section`
  width: 100%;
  background-color: ${({ theme }) => theme.colorTokens.purple[700]};
  align-content: center;
  padding: 2rem 0;
`;

const ContainerCss = styled(WidthConstraints)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 73.5625rem;
  width: 100%;
  padding: 0 15px;

  ${createMediaQuery(
    'md',
    css`
      flex-direction: row;
      padding: 2.5rem;
    `
  )};
`;

const TextContainerCss = styled.div`
  padding: 0;
  text-align: center;
  margin: 0;

  ${createMediaQuery(
    'md',
    css`
      text-align: left;
      margin: 0 2rem 0 0;
      max-width: 40.625rem;
    `
  )}
`;

const TitleCss = styled.h2`
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colorTokens.default.white};
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-size: 1.75rem;

  ${createMediaQuery(
    'md',
    css`
      font-size: 3.125rem;
    `
  )}
`;

const IngressCss = styled.p`
  color: ${({ theme }) => theme.colorTokens.default.white};
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-size: 1.25rem;

  ${createMediaQuery(
    'md',
    css`
      font-size: 1.75rem;
    `
  )}
`;

const TryJuridikaSection: FC = () => {
  return (
    <TryJuridikaSectionCss>
      <ContainerCss defaultResponsiveWidth size="large">
        <TextContainerCss>
          <TitleCss>Prøv Juridika gratis!</TitleCss>
          <IngressCss>
            Få gratis prøvetilgang til deg og dine kolleger, og se hvordan Juridika kan gjøre din arbeidshverdag enklere.
          </IngressCss>
        </TextContainerCss>
        <CTAButtonCss href="/prøv-juridika"> Prøv Juridika nå</CTAButtonCss>
      </ContainerCss>
    </TryJuridikaSectionCss>
  );
};

export default TryJuridikaSection;

const CTAButtonCss = styled.a`
  ${linkWithoutStyle}
  background-color: ${({ theme }) => theme.colorTokens.red[700]};
  padding: 16px 32px;
  color: ${({ theme }) => theme.colorTokens.default.white};
  border-radius: 4px;
  display: inline-block;
  font-size: 1.25rem;
  font-weight: 600;
`;

import * as React from 'react';

const SelmerLogo: React.FC = () => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 130.4 29.9">
    <g>
      <g>
        <path
          d="M0,28.2c12.2,3.4,24,1.4,25.7-4C27,20,22,14.7,13.8,11l-0.3,0.4c5.1,3.3,7.8,7.1,6.5,9.8c-1.6,3.1-8.8,3.6-16.3,1.7
			C3.7,22.9-0.1,28.2,0,28.2z"
        />
        <path
          d="M5.9,11.5C4.5,8.7,4.2,4.8,5.7,2.3C3.3,2.8,0.2,5.4,0.2,8.7c0.1,4.3,4.2,8.3,12.7,10.1l0.2-0.4
			C10.1,16.9,7.5,14.5,5.9,11.5z"
        />
        <path d="M9.4,7.8L9.7,8c2.8-2.7,6.8-3.5,12.2-2.2l3.6-5.2C17.3-1.5,10.5,2.7,9.4,7.8z" />
      </g>
      <g>
        <polygon points="69.6,19.4 69.6,8.2 66.2,8.2 66.2,21.7 76.9,21.7 76.9,19.4 		" />
        <path
          d="M46.7,14.3c-1.1-0.6-2.4-0.8-3.7-0.8h-4.5c-0.5,0-1-0.1-1.5-0.3c-0.3-0.2-0.5-0.6-0.4-1v-0.6c-0.1-0.4,0.1-0.8,0.4-1
			c0.5-0.3,1-0.4,1.5-0.3h8.8V8.2h-9.2c-1.3-0.1-2.5,0.2-3.6,0.8c-0.9,0.5-1.4,1.5-1.3,2.6v1.2c-0.1,1,0.4,2,1.3,2.6
			c1.1,0.6,2.4,0.8,3.6,0.8h4.5c0.5-0.1,1.1,0.1,1.5,0.3c0.3,0.2,0.4,0.6,0.4,1v0.9c0.1,0.4-0.1,0.8-0.4,1c-0.5,0.3-1,0.4-1.5,0.3
			h-9.5v2.3H43c1.3,0.1,2.5-0.2,3.7-0.8c0.9-0.5,1.4-1.5,1.3-2.6v-1.5C48.1,15.9,47.5,14.9,46.7,14.3z"
        />
        <polygon
          points="113.1,10.5 113.1,8.2 99.9,8.2 99.9,21.7 113.1,21.7 113.1,19.4 103.3,19.4 103.3,15.8 112.8,15.8 112.8,13.7
			103.3,13.7 103.3,10.5 		"
        />
        <polygon
          points="63.6,10.5 63.6,8.2 50.4,8.2 50.4,21.7 63.6,21.7 63.6,19.4 53.8,19.4 53.8,15.8 63.3,15.8 63.3,13.7 53.8,13.7
			53.8,10.5 		"
        />
        <path
          d="M91.6,8.2l-3.8,9.6c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0L84,8.2h-5v13.6h3.2V11.1l0,0c0,0,0,0,0.1,0l4.3,10.7H89l4.3-10.7
			c0,0,0,0,0.1,0l0,0v10.7h3.2V8.2H91.6z"
        />
        <path
          d="M127.1,21.7h3.3l-3.9-5.1c0.7-0.1,1.4-0.3,2.1-0.7c0.9-0.5,1.4-1.5,1.3-2.6v-1.9c0.1-1-0.4-2-1.3-2.6
			c-1.1-0.6-2.3-0.8-3.5-0.8h-9.2v13.6h3.3v-5h4.1L127.1,21.7z M119.1,14.4v-3.9h5.9c0.5,0,1,0.1,1.5,0.3c0.3,0.2,0.4,0.6,0.4,1v1.3
			c0.1,0.4-0.1,0.8-0.4,1c-0.4,0.2-0.9,0.4-1.4,0.3L119.1,14.4L119.1,14.4z"
        />
      </g>
    </g>
  </svg>
);

export default SelmerLogo;

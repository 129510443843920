import * as React from 'react';

const HaavindLogo: React.FC = () => (
  <svg viewBox="0 0 220 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_10)">
      <path
        d="M71.1406 39.6201V10.3076H76.6277V22.2774H90.1536V10.3076H95.638V39.6201H90.1536V26.9321H76.6277V39.6201H71.1406Z"
        fill="black"
      />
      <path
        d="M100.117 34.0084C100.117 29.4027 103.675 27.8113 108.072 27.2264L111.004 26.8483C112.68 26.598 113.265 26.0104 113.265 25.0065C113.265 23.581 112.092 22.3269 109.916 22.3269C107.528 22.3269 106.02 23.6654 105.811 25.8009H100.536C100.871 21.5298 104.345 18.5156 109.622 18.5156C115.863 18.5156 118.374 21.8644 118.374 27.6018V39.6206H113.643V38.7827C113.65 38.1219 113.72 37.4632 113.853 36.8158C112.764 38.8235 110.67 40.3742 107.152 40.3742C103.591 40.3742 100.117 38.0291 100.117 34.0084ZM113.447 31.7069V29.4762C112.985 29.8951 112.275 30.1454 111.018 30.3549L109.342 30.6895C106.945 31.1084 105.313 31.9572 105.313 33.7989C105.313 35.6406 106.736 36.6064 108.537 36.6064C111.255 36.6064 113.434 34.596 113.434 31.7069H113.447Z"
        fill="black"
      />
      <path
        d="M122.266 34.0084C122.266 29.4027 125.827 27.8113 130.223 27.2264L133.153 26.8483C134.828 26.598 135.416 26.0104 135.416 25.0065C135.416 23.581 134.244 22.3269 132.065 22.3269C129.679 22.3269 128.172 23.6654 127.962 25.8009H122.685C123.019 21.5298 126.493 18.5156 131.771 18.5156C138.011 18.5156 140.525 21.8644 140.525 27.6018V39.6206H135.781V38.7827C135.788 38.122 135.859 37.4634 135.99 36.8158C134.902 38.8235 132.807 40.3742 129.29 40.3742C125.772 40.3742 122.266 38.0291 122.266 34.0084ZM135.582 31.7069V29.4762C135.122 29.8951 134.41 30.1454 133.153 30.3549L131.48 30.6895C129.091 31.1084 127.459 31.9463 127.459 33.788C127.459 35.6297 128.882 36.5955 130.683 36.5955C133.406 36.6064 135.582 34.596 135.582 31.7069Z"
        fill="black"
      />
      <path
        d="M150.363 39.6203L142.656 19.1846H148.143L152.624 32.6697C152.936 33.5299 153.187 34.4107 153.377 35.3058C153.554 34.4118 153.793 33.531 154.09 32.6697L158.527 19.1846H163.799L156.305 39.6203H150.363Z"
        fill="black"
      />
      <path d="M166.566 10.7266H171.928V16.0858H166.566V10.7266ZM171.803 19.1843V39.6201H166.694V19.1843H171.803Z" fill="black" />
      <path
        d="M176.996 39.6206V19.1848H182.105V20.1071C182.104 20.7807 182.048 21.4531 181.936 22.1175C183.068 20.0635 184.994 18.5156 188.218 18.5156C192.742 18.5156 195.421 21.6985 195.421 26.7231V39.6206H190.313V27.4767C190.313 24.4625 188.846 22.5799 186.46 22.5799C183.947 22.5799 182.108 24.7998 182.108 27.6453V39.6152L176.996 39.6206Z"
        fill="black"
      />
      <path
        d="M199.312 29.4762C199.312 22.1474 203.962 18.5048 208.736 18.5048C211.916 18.5048 213.886 20.137 215.058 22.1066C214.924 21.4454 214.867 20.7707 214.89 20.0962V10.3027H219.999V39.6206H214.89V38.7828C214.862 38.1226 214.918 37.4617 215.058 36.8159C214.261 38.323 212.419 40.3742 208.652 40.3742C203.246 40.3742 199.312 36.688 199.312 29.4762ZM215.183 29.4327C215.183 25.246 212.923 22.5664 209.865 22.5664C206.807 22.5664 204.506 25.3711 204.506 29.4327C204.506 33.663 206.807 36.3426 209.865 36.3426C212.923 36.3426 215.183 33.5568 215.183 29.4436V29.4327Z"
        fill="black"
      />
      <path d="M40.203 0H29.9062V49.9196H40.203V0Z" fill="black" />
      <path d="M10.2968 0H0V49.9196H10.2968V0Z" fill="black" />
      <path
        d="M20.1087 34.7643C25.519 34.7643 29.9049 30.3784 29.9049 24.9681C29.9049 19.5578 25.519 15.1719 20.1087 15.1719C14.6984 15.1719 10.3125 19.5578 10.3125 24.9681C10.3125 30.3784 14.6984 34.7643 20.1087 34.7643Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_10">
        <rect width="220" height="50" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default HaavindLogo;

import * as React from 'react';

const PwCLogo: React.FC = () => (
  <svg version="1.1" id="PwC" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 283.5 215.1">
    <g>
      <g>
        <path
          d="M191.9,142.1c-9.1,1.5-13.8,8.2-13.8,20.1s6.2,19.9,15.7,19.9c4.4,0,8.5-1.5,16.9-5.6v9.6c-10.2,4.7-16.1,6.1-24.3,6.1
			c-8.9,0-15.1-2.4-20.2-7.6c-5.2-5.3-8-12.4-7.8-19.8c0-17.1,12.6-28.6,31.1-28.6c12.3,0,20.7,5.6,20.7,13.8c0,5.3-3.9,8.9-9.8,8.9
			c-3.1,0-6-0.9-8.6-2.6L191.9,142.1z"
        />
        <path
          d="M146.9,164.9c8.2-10.3,11.1-14.5,11.1-19.5c0-5-4.1-9.1-9.1-9.1c-0.1,0-0.2,0-0.3,0c-2.9,0-5.7,1.1-7.7,3.2v21l-13.4,17.9
			v-40.7h-12.7l-21.2,35.1v-35.1h-7.3l-19.2,4.7v4.9l10.5,1.1v43.1h13.6l20.3-33.4v33.4h14.8L146.9,164.9z"
        />
        <path
          d="M26.6,184.7c1.5,0.1,2.1,0.1,3,0.1c10.9,0,16.8-6.8,16.8-19.9c0-11-4.8-16.9-13.6-16.9c-2.1,0.1-4.1,0.3-6.1,0.6
			L26.6,184.7z M26.6,208.2l8.8,1.9v5H0.9v-5l7.9-1.9V148H0v-5.2l21.1-6.4h5.5v7.2c9.9-5.9,13.3-6.9,18.3-6.9
			c11.7,0,20.3,10.8,20.3,25.7c0,17.3-11.5,28.9-29.7,28.9c-3,0-6-0.2-9-0.5L26.6,208.2z"
        />
      </g>
      <rect x="127.6" y="92.4" width="52.8" height="13.2" />
      <path
        d="M270.1,66V26.4h-26.7V10.3H233V0h-52.6v92.4h103.1V66H270.1z M267.4,29v37h-24.1V29H267.4z M183,55.4h27.6V66H183V55.4z
		 M183,13h47.4v13.4H183V13z M230.4,29v37h-17.1V52.8H183V29H230.4z M183,68.6h27.6v21.1H183V68.6z M213.3,68.6h17.1v21.1h-17.1
		L213.3,68.6z M233,68.6h7.7v21.1H233L233,68.6z M233,66V29h7.7v37H233z M240.7,13v13.4H233V13H240.7z M183,2.6h47.4v7.7H183V2.6z
		 M243.4,89.8V68.6h24.1v21.1H243.4z M280.8,89.8h-10.8V68.6h10.8L280.8,89.8z"
      />
    </g>
  </svg>
);

export default PwCLogo;

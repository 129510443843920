import { NoticeFeed } from 'components/NoticeFeed';
import { Catch } from 'components/error/Catch';
import { InlineError } from 'components/error/InlineError';
import { handleTruncate } from 'pages/JuridikaInsightPage/JuridikaInsightPage';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import createMediaQuery from 'util/createMediaQuery';

const MarketingArticlesSection: FC = () => {
  return (
    <MarketingArticlesSectionCss>
      <TitleCss>Aktuelt</TitleCss>
      <Catch catchId="marketing-articles" renderError={(props) => <InlineError {...props} />}>
        <NoticeFeed
          feed="forside-reklame"
          initialLoadCount={2}
          displayMoreInterval={0}
          showFirstAsLatestStyle={false}
          displayCategory
          displayImage
          displayPublishedAt={false}
          onTruncate={() => handleTruncate('forside-reklame')}
        />
      </Catch>
    </MarketingArticlesSectionCss>
  );
};
export default MarketingArticlesSection;

const MarketingArticlesSectionCss = styled.section`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
  padding: 48px 0;
`;

const TitleCss = styled.h2`
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colorTokens.default.black};
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-size: 1.75rem;
  text-align: center;
  font-weight: 500;

  ${createMediaQuery(
    'md',
    css`
      font-size: 3.125rem;
    `
  )}
`;

import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { AuthenticationStatus } from 'state/login/types';
import { useSelector } from 'util/hooks/useSelector';
import { generatePageTitle } from 'util/pageTitleUtils';
import { HOME_PAGE_DESCRIPTION } from 'util/metaDescription';
import { AvoidBankruptcyWebinarPromo } from 'components/Webinar/WebinarPromo';
import WhoWeAreSection from 'pages/HomePage/components/WhoWeAreSection';
import QuoteSection from 'pages/HomePage/components/QuoteSection';
import CustomersSection from 'pages/HomePage/components/CustomersSection';
import TryJuridikaSection from 'pages/HomePage/components/TryJuridikaSection';
import { Layout } from '../../containers/Layouts/Layout';
import PageviewStatisticsLogger from '../../containers/StatisticsLogger/PageviewStatisticsLogger';
import GetStartedHeroSection from './components/GetStartedHeroSection';
import DiagonalBooksSection from './components/DiagonalBooksSection';
import IdealUserProfileSection from './components/IdealUserProfileSection';
import MarketingArticlesSection from './components/MarketingArticlesSection';

const HomePage: FC = () => {
  const location = useLocation();
  const authStatus = useSelector((state) => state.login.authStatus);
  const history = useHistory();

  if (authStatus === AuthenticationStatus.AUTHENTICATED) history.replace('/start');

  return (
    <Layout
      location={location}
      scrollToTopOnPathChange
      hasBeigeBackground
      hasToolbar={false}
      webinarPromo={<AvoidBankruptcyWebinarPromo />}
      livechatVisibility="minimized"
    >
      <Helmet>
        <title>{generatePageTitle('Juridisk litteratur, rettskilder og innsiktsartikler')} </title>
        <meta name="description" content={HOME_PAGE_DESCRIPTION} />
      </Helmet>
      <PageviewStatisticsLogger title="Juridika" />
      <GetStartedHeroSection />
      <DiagonalBooksSection />
      <WhoWeAreSection />
      <IdealUserProfileSection />
      <QuoteSection>
        <p>
          Juridika effektiviserer arbeidshverdagen min fordi jeg så enkelt og raskt får tilgang på akkurat det jeg trenger. Veldig
          nyttig!
        </p>
        <footer>- Lise Gran, Senioradvokat i Homble Olsby advokatfirma</footer>
      </QuoteSection>
      <MarketingArticlesSection />
      <CustomersSection />
      <TryJuridikaSection />
    </Layout>
  );
};

export default HomePage;

import * as React from 'react';
import styled from 'styled-components';

interface MediaWrapperProps {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}

const MediaWrapperCss = styled.div`
  width: 100%;
  text-align: center;

  img,
  video {
    max-width: 100%;
    outline: none;
  }

  /* 
    was not able to find another way of removing the extra white 
    space beneath video-tag, hence the negative unit
  */
  video {
    margin-bottom: -4.5px;
  }
`;

const MediaWrapper: React.FC<MediaWrapperProps> = ({ className, children }) => (
  <MediaWrapperCss className={className}>{children}</MediaWrapperCss>
);

export default MediaWrapper;

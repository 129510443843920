import * as React from 'react';

const SkatteetatenLogo: React.FC = () => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 356.9 84.9">
    <g>
      <g>
        <rect width="84.9" height="84.9" />
        <path
          fill="#FFFFFF"
          d="M55,31.7L42.5,44.4v-7.2L55,24.6c-4.1-1.2-8.3-1.7-12.5-1.7V30c-14.6,0-26.4,6.5-26.4,14.4
			s11.8,14.4,26.4,14.4s26.4-6.4,26.4-14.4C68.9,38.9,63.2,34.2,55,31.7z"
        />
      </g>
      <path
        d="M114.7,59.9c-1.9,0-3.7-0.2-5.5-0.7c-1.5-0.4-2.9-1.1-4.2-2c-1.1-0.8-2.1-2-2.7-3.2c-0.6-1.4-1-2.9-1-4.4h7
		c0.1,1.4,0.8,2.8,1.9,3.6c1.4,0.8,2.9,1.2,4.5,1.1c0.7,0,1.3-0.1,2-0.2c0.6-0.1,1.1-0.3,1.7-0.6c0.5-0.3,0.9-0.6,1.2-1.1
		c0.3-0.5,0.4-1,0.4-1.6c0-0.6-0.1-1.2-0.5-1.6c-0.4-0.5-0.8-0.8-1.4-1.1c-0.7-0.3-1.4-0.6-2.2-0.8c-0.9-0.2-1.8-0.5-2.9-0.7
		c-1.5-0.3-2.8-0.7-4.2-1.1c-1.2-0.4-2.4-0.9-3.4-1.6c-1-0.6-1.7-1.5-2.3-2.5c-0.6-1.2-0.9-2.5-0.8-3.9c0-1.4,0.3-2.8,0.9-4.1
		c0.6-1.1,1.4-2.1,2.5-2.8c1.1-0.8,2.4-1.3,3.7-1.7c1.5-0.4,3.1-0.6,4.7-0.5c1.6,0,3.1,0.2,4.6,0.6c1.3,0.3,2.6,0.9,3.7,1.7
		c1,0.8,1.9,1.7,2.5,2.8c0.6,1.2,1,2.6,1.1,3.9h-6.8c-0.1-1.1-0.7-2.1-1.6-2.8c-1.1-0.7-2.3-1.1-3.6-1c-1.2-0.1-2.4,0.2-3.5,0.8
		c-0.8,0.5-1.3,1.3-1.3,2.3c0,0.5,0.2,1,0.5,1.5c0.4,0.4,0.9,0.8,1.4,1c0.7,0.3,1.5,0.6,2.3,0.8l3.2,0.7c1.3,0.3,2.6,0.6,3.9,1.1
		c1.2,0.4,2.3,1,3.3,1.7c2.1,1.5,3.3,4.1,3.2,6.7c0.1,2.8-1.2,5.5-3.5,7.2c-1.2,0.9-2.5,1.5-3.9,1.9C118,59.7,116.4,60,114.7,59.9"
      />
      <path
        d="M139,49.8l-2.2,2.2v7.2h-6.3V28.9h6.3v9.4c0,1.6,0,3-0.1,4.2s-0.1,2.1-0.1,2.8l7.1-7.8h7.4l-7.7,8l8.6,13.7h-7.4L139,49.8z
		"
      />
      <path
        d="M162.2,55.6c1.3,0.1,2.5-0.4,3.4-1.2c0.8-0.8,1.3-1.9,1.3-3v-2.8c-0.5,0.3-1.1,0.6-1.7,0.7c-0.7,0.2-1.4,0.3-2.1,0.5
		c-1.1,0.2-2.2,0.6-3.1,1.1c-0.7,0.5-1.1,1.3-1,2.1c-0.1,0.8,0.3,1.6,0.9,2C160.6,55.4,161.4,55.6,162.2,55.6 M167.6,59.2
		c-0.2-0.3-0.3-0.6-0.4-1c-0.2-0.5-0.2-1.1-0.3-1.6h-0.1c-0.7,1-1.5,1.8-2.5,2.4c-1.2,0.6-2.6,0.9-4,0.8c-1,0-2-0.1-3-0.4
		c-0.9-0.3-1.7-0.7-2.4-1.2c-0.7-0.6-1.2-1.3-1.6-2.1c-0.4-0.9-0.6-1.9-0.6-2.8c0-1,0.2-2.1,0.7-3c0.4-0.8,1.1-1.4,1.8-1.9
		c0.9-0.5,1.8-0.9,2.8-1.1c1.1-0.3,2.2-0.5,3.3-0.6c1.4-0.2,2.8-0.5,4.2-1c0.8-0.3,1.3-1,1.3-1.8c0-0.7-0.3-1.4-0.8-1.8
		c-0.7-0.5-1.6-0.8-2.4-0.7c-1-0.1-2,0.2-2.8,0.8c-0.6,0.5-1,1.3-1,2.1h-5.9c0-0.9,0.3-1.8,0.6-2.7c0.4-0.9,1-1.7,1.8-2.3
		c0.9-0.7,1.9-1.3,3-1.6c1.4-0.4,2.8-0.6,4.2-0.6c1.2,0,2.4,0.1,3.6,0.3c0.9,0.2,1.8,0.5,2.6,1c1.1,0.6,2,1.5,2.5,2.6
		c0.5,1.2,0.8,2.5,0.8,3.8v11.1c0,1.7,0.3,2.7,0.8,3v0.4L167.6,59.2z"
      />
      <path
        d="M175.8,37.5h3.3v-6.9h6.3v6.9h4.2v4.1h-4.2v10.8c-0.1,0.6,0.2,1.2,0.6,1.6c0.5,0.3,1.2,0.5,1.8,0.4h0.7l0.7-0.1h0.3v4.8
		l-1.5,0.2c-0.7,0.1-1.5,0.1-2.2,0.1c-0.9,0-1.8-0.1-2.7-0.3c-0.8-0.2-1.5-0.5-2.1-0.9c-0.6-0.5-1.1-1.1-1.4-1.8
		c-0.4-0.9-0.5-1.8-0.5-2.8V41.6h-3.3L175.8,37.5z"
      />
      <path
        d="M191.5,37.5h3.3v-6.9h6.3v6.9h4.2v4.1H201v10.8c-0.1,0.6,0.2,1.2,0.6,1.7c0.5,0.3,1.2,0.5,1.8,0.4h0.7l0.7-0.1h0.3v4.8
		l-1.5,0.2c-0.7,0.1-1.5,0.1-2.2,0.1c-0.9,0-1.8-0.1-2.7-0.3c-0.8-0.2-1.5-0.5-2.1-0.9c-0.6-0.5-1.1-1.1-1.4-1.8
		c-0.4-0.9-0.5-1.8-0.5-2.8V41.6h-3.3L191.5,37.5z"
      />
      <path
        d="M218.7,41.5c-1.3-0.1-2.5,0.4-3.3,1.3c-0.8,1-1.3,2.2-1.5,3.5h9.2c0-1.3-0.5-2.6-1.3-3.6C221,41.9,219.8,41.4,218.7,41.5
		 M219,59.9c-1.6,0-3.2-0.3-4.8-0.9c-1.4-0.6-2.6-1.4-3.6-2.4c-1-1-1.8-2.3-2.3-3.6c-0.5-1.4-0.8-3-0.8-4.5c0-1.6,0.3-3.1,0.8-4.5
		c0.5-1.3,1.3-2.6,2.3-3.6c1-1,2.2-1.9,3.5-2.4c1.4-0.6,3-0.9,4.5-0.9c1.4,0,2.8,0.2,4.2,0.7c2.9,1.1,5.1,3.5,6,6.5
		c0.6,1.9,0.9,3.9,0.8,5.9h-15.8c0.1,1.4,0.7,2.8,1.6,3.8c0.9,1,2.3,1.5,3.6,1.4c0.9,0,1.7-0.2,2.5-0.5c0.6-0.4,1.1-0.9,1.5-1.6h6.2
		c-0.3,1-0.7,1.9-1.3,2.7c-0.6,0.8-1.3,1.6-2.2,2.2c-0.9,0.6-1.9,1.1-3,1.4C221.5,59.7,220.2,59.9,219,59.9"
      />
      <path
        d="M242.9,41.5c-1.3-0.1-2.5,0.4-3.3,1.3c-0.8,1-1.3,2.2-1.5,3.5h9.2c0-1.3-0.5-2.6-1.4-3.6C245.2,41.9,244.1,41.4,242.9,41.5
		 M243.2,59.9c-1.6,0-3.2-0.3-4.8-0.9c-1.4-0.5-2.6-1.4-3.6-2.4c-1-1-1.8-2.3-2.3-3.6c-1.1-2.9-1.1-6.1,0-9.1
		c0.5-1.3,1.3-2.6,2.3-3.6c1-1,2.2-1.9,3.5-2.4c2.8-1.1,5.9-1.2,8.7-0.2c2.9,1.1,5.2,3.5,6,6.5c0.6,1.9,0.9,3.9,0.8,5.9h-15.8
		c0.1,1.4,0.7,2.8,1.6,3.8c0.9,1,2.3,1.5,3.6,1.4c0.9,0,1.7-0.2,2.5-0.5c0.6-0.4,1.2-0.9,1.5-1.6h6.2c-0.3,1-0.7,1.9-1.3,2.7
		c-0.6,0.8-1.3,1.6-2.2,2.2c-0.9,0.6-1.9,1.1-3,1.4C245.8,59.7,244.5,59.9,243.2,59.9"
      />
      <path
        d="M255.4,37.5h3.3v-6.9h6.3v6.9h4.2v4.1H265v10.8c-0.1,0.6,0.2,1.2,0.6,1.7c0.5,0.3,1.2,0.5,1.8,0.4h0.7l0.7-0.1h0.3v4.8
		l-1.5,0.2c-0.7,0.1-1.5,0.1-2.2,0.1c-0.9,0-1.8-0.1-2.7-0.3c-0.8-0.2-1.5-0.5-2.1-0.9c-0.6-0.5-1.1-1.1-1.4-1.8
		c-0.3-0.9-0.5-1.8-0.5-2.8V41.6h-3.3L255.4,37.5z"
      />
      <path
        d="M281.4,55.6c1.3,0.1,2.5-0.4,3.4-1.2c0.8-0.8,1.3-1.9,1.3-3v-2.8c-0.5,0.3-1.1,0.6-1.7,0.7c-0.7,0.2-1.4,0.3-2.1,0.5
		c-1.1,0.2-2.2,0.6-3.1,1.1c-0.7,0.5-1.1,1.3-1,2.1c-0.1,0.8,0.3,1.6,0.9,2C279.9,55.4,280.7,55.6,281.4,55.6 M286.8,59.2
		c-0.2-0.3-0.3-0.6-0.4-1c-0.2-0.5-0.3-1.1-0.3-1.6H286c-0.7,1-1.5,1.8-2.5,2.4c-1.2,0.6-2.6,0.9-4,0.8c-1,0-2-0.1-3-0.4
		c-0.9-0.3-1.7-0.7-2.4-1.2c-0.7-0.6-1.2-1.3-1.5-2.1c-0.4-0.9-0.6-1.9-0.5-2.8c0-1,0.2-2.1,0.7-3c0.4-0.8,1.1-1.4,1.8-1.9
		c0.8-0.5,1.7-0.9,2.7-1.1c1.1-0.3,2.2-0.5,3.3-0.6c1.4-0.2,2.8-0.5,4.2-1c0.8-0.2,1.3-0.9,1.3-1.7c0-0.7-0.3-1.4-0.8-1.9
		c-0.7-0.5-1.6-0.8-2.5-0.7c-1-0.1-2,0.2-2.8,0.8c-0.6,0.5-1,1.3-1,2.1h-5.9c0-0.9,0.3-1.8,0.6-2.7c0.4-0.9,1-1.7,1.8-2.3
		c0.9-0.7,1.9-1.3,3-1.6c1.4-0.4,2.8-0.6,4.2-0.6c1.2,0,2.4,0.1,3.6,0.3c0.9,0.2,1.8,0.5,2.6,1c1.1,0.6,2,1.5,2.5,2.6
		c0.6,1.2,0.8,2.5,0.8,3.8v11.1c0,1.7,0.3,2.7,0.8,3v0.4L286.8,59.2z"
      />
      <path
        d="M295.1,37.5h3.2v-6.9h6.3v6.9h4.2v4.1h-4.2v10.8c-0.1,0.6,0.2,1.2,0.6,1.7c0.5,0.3,1.2,0.5,1.8,0.4h0.7l0.7-0.1h0.3v4.8
		l-1.5,0.2c-0.7,0.1-1.5,0.1-2.2,0.1c-0.9,0-1.8-0.1-2.8-0.3c-0.8-0.2-1.5-0.5-2.1-0.9c-0.6-0.5-1.1-1.1-1.4-1.8
		c-0.4-0.9-0.5-1.8-0.5-2.8V41.6h-3.2L295.1,37.5z"
      />
      <path
        d="M322.3,41.5c-1.3-0.1-2.5,0.4-3.3,1.3c-0.8,1-1.3,2.2-1.5,3.5h9.2c0-1.3-0.5-2.6-1.3-3.6C324.6,41.9,323.4,41.4,322.3,41.5
		 M322.6,59.9c-1.6,0-3.2-0.3-4.8-0.9c-1.4-0.5-2.6-1.4-3.6-2.4c-1-1-1.8-2.3-2.3-3.6c-0.5-1.4-0.8-3-0.8-4.5c0-1.6,0.3-3.1,0.8-4.5
		c0.5-1.4,1.3-2.6,2.3-3.6c1-1,2.2-1.9,3.5-2.4c2.8-1.1,5.9-1.2,8.7-0.2c1.2,0.5,2.3,1.1,3.3,2c1.3,1.2,2.2,2.8,2.7,4.4
		c0.6,1.9,0.9,3.9,0.8,5.9h-15.8c0.1,1.4,0.7,2.8,1.6,3.9c1,1,2.3,1.5,3.6,1.4c0.9,0,1.7-0.2,2.5-0.5c0.6-0.4,1.1-0.9,1.5-1.6h6.2
		c-0.3,1-0.7,1.9-1.3,2.7c-0.6,0.8-1.3,1.6-2.2,2.2c-0.9,0.6-1.9,1.1-3,1.4C325.1,59.7,323.8,59.9,322.6,59.9"
      />
      <path
        d="M342.6,40.8h0.1c0.6-1.2,1.5-2.2,2.7-2.9c1.2-0.7,2.6-1,4-1c2.1-0.1,4.1,0.7,5.6,2.2c1.4,1.6,2.1,3.7,2,5.8v14.2h-6.3v-13
		c0-1-0.3-2.1-0.9-2.9c-0.7-0.8-1.7-1.1-2.7-1.1c-1.2,0-2.3,0.5-3,1.3c-0.8,0.9-1.2,2.1-1.2,3.4v12.2h-6.4V37.5h6.2V40.8z"
      />
    </g>
  </svg>
);

export default SkatteetatenLogo;

import * as React from 'react';

const EurojurisLogo: React.FC = () => (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 243.95 31.97">
    <g>
      <path d="M20.66,11.74a7.16,7.16,0,0,0,0,1.73H52v3H21.53c3,6,12.68,11.29,24.77,13,15.23,2.14,28.45-2.31,29.52-10S65.41,3.93,50.18,1.78s-28.45,2.32-29.52,10ZM0,16.09C0,7.32,21.32.22,47.62.22s47.63,7.1,47.63,15.87S73.93,32,47.62,32,0,24.86,0,16.09Z" />
      <path d="M109,8.81c2.67,0,3.65.09,5.47.21a3.05,3.05,0,0,1-.14-1,2.9,2.9,0,0,1,.14-1c-1.82.09-3.64.12-5.47.19,0-1.73.1-3.43.1-5.16,1.85.09,3.67.14,5.52.29a3.09,3.09,0,0,1-.15-1.06,3.34,3.34,0,0,1,.15-1c-1.71.15-3.48.19-6.12.19a18.65,18.65,0,0,1-3.15-.19c.12,2.81.15,5.59.15,8.4,0,2.62-.12,5.21-.22,7.83,1.49-.08,3.22-.12,4.9-.12,1.44,0,2.85.07,4.29.12a4.08,4.08,0,0,1-.07-.92c0-.4,0-.6.07-1.08a45.46,45.46,0,0,1-5.47.29c0-1,0-1.89,0-2.85l0-2.72V8.81Z" />
      <path d="M119.46,9.26c0,.2,0,.36,0,.53a7.93,7.93,0,0,0,.74,4.06c.94,1.85,3.36,3,6.46,3,2.61,0,5.23-1.08,6.36-3.6.5-1.12.65-2.25.69-5.28,0-2.54.12-5.11.29-7.65a6.46,6.46,0,0,1-1.29.12,4.48,4.48,0,0,1-1.06-.12c.1,1.92.14,3.81.14,5.73,0,4.11,0,5-.43,6.24a4,4,0,0,1-4.08,3C123,15.22,123,11.16,123,8L123,4.1a32.38,32.38,0,0,1,.24-3.81,16,16,0,0,1-1.9.12,15.8,15.8,0,0,1-2-.12c.1,2.16.12,4.56.12,5.69s-.05,3.26-.05,3.28Z" />
      <path
        d="M139.63,13.34c0,1.08-.07,1.66-.21,3.17a13.37,13.37,0,0,1,1.85-.09,9.11,9.11,0,0,1,2,.16c-.09-1.39-.12-1.82-.12-2.56l0-2.43c0-.74,0-1.46,0-2.21,0-.19.07-.31.17-.31s.1,0,.26.36c1.4,2.35,2.79,4.73,4,7.15.84,0,1.17-.07,1.89-.07s1.2,0,2.38.07l-1.06-1.68-2.11-3.28c-1.06-1.66-1.25-2-1.9-3,2.14-.58,3.82-1.8,3.82-4.2,0-3.12-2.45-4.08-5.11-4.08-1.25,0-2.52.12-3.77.12-.48,0-.82,0-2.14-.12.12,2.47.17,3.81.17,6.24,0,5.06-.05,6.53-.05,6.81Zm3.44-5.25c.07-2.14,0-4.27.09-6.41l.75,0c2,0,2.92,1,2.92,3,0,2.52-1.36,3.46-3.76,3.46Z"
        fillRule="evenodd"
      />
      <path
        d="M158.52,15.5a9.17,9.17,0,0,0,5.42,1.4c5.36,0,9-3.29,9.24-8.38,0-5.62-3.5-8.52-9-8.52C158.35,0,155,3.77,155,8.69a7.72,7.72,0,0,0,3.48,6.81Zm5.66-14.13c3.27,0,5.07,2.57,5.07,7.15,0,4.13-2.19,7-5.31,7s-5-2.47-5-6.94c0-3.48,1.27-7.22,5.23-7.22Z"
        fillRule="evenodd"
      />
      <path d="M178.78,8.81l-.05,4-.07,2.74c-.08,2.62-.56,3.48-2,3.62a5.17,5.17,0,0,1,.34,1.06,5.36,5.36,0,0,0,4.8-3,7.38,7.38,0,0,0,.58-3.6c0-1.9-.08-3.77-.08-5.67,0-2.57.15-5.11.27-7.68a20.52,20.52,0,0,1-2.07.12,14.73,14.73,0,0,1-1.87-.12c.07,1.73.17,3.51.17,4.87l0,3.63Z" />
      <path d="M188.07,9.26c0,.2,0,.36,0,.53a7.93,7.93,0,0,0,.74,4.06c.94,1.85,3.36,3,6.46,3,2.61,0,5.23-1.08,6.36-3.6.5-1.12.64-2.25.69-5.28,0-2.54.12-5.11.29-7.65a6.49,6.49,0,0,1-1.3.12,4.45,4.45,0,0,1-1-.12c.1,1.92.14,3.81.14,5.73,0,4.11,0,5-.43,6.24a4,4,0,0,1-4.08,3c-4.25,0-4.29-4.06-4.29-7.25l.07-3.87a32.38,32.38,0,0,1,.24-3.81,16,16,0,0,1-1.9.12,15.8,15.8,0,0,1-2-.12c.1,2.16.12,4.56.12,5.69s-.05,3.26-.05,3.28Z" />
      <path
        d="M208.24,13.34c0,1.08-.07,1.66-.21,3.17a13.37,13.37,0,0,1,1.85-.09,9.11,9.11,0,0,1,2,.16c-.09-1.39-.12-1.82-.12-2.56l0-2.43c0-.74,0-1.46,0-2.21,0-.19.07-.31.17-.31s.1,0,.26.36c1.4,2.35,2.79,4.73,4,7.15.84,0,1.17-.07,1.89-.07s1.2,0,2.38.07l-1.06-1.68-2.11-3.28c-1.06-1.66-1.25-2-1.9-3,2.14-.58,3.82-1.8,3.82-4.2,0-3.12-2.45-4.08-5.11-4.08-1.25,0-2.52.12-3.77.12-.48,0-.82,0-2.14-.12.12,2.47.17,3.81.17,6.24,0,5.06,0,6.53,0,6.81Zm3.44-5.25c.07-2.14,0-4.27.09-6.41l.75,0c2,0,2.92,1,2.92,3,0,2.52-1.36,3.46-3.76,3.46Z"
        fillRule="evenodd"
      />
      <path d="M224.73,16.54a16,16,0,0,1,2.06-.12,16.91,16.91,0,0,1,1.92.12c-.12-1.95-.14-3.39-.14-5.74,0-3.5.14-7,.43-10.49a13.21,13.21,0,0,1-1.89.12A16,16,0,0,1,225,.31c.1,1.56.12,1.83.12,4.25,0,3,0,7.32-.38,12Z" />
      <path d="M242.58,3.53a8.55,8.55,0,0,1,.8-2.31A6.43,6.43,0,0,0,239.46.1C236.2.1,234.09,2,234.09,5c0,5.23,7,4.59,7,8a2.58,2.58,0,0,1-2.78,2.43,3.15,3.15,0,0,1-3.34-2.6h-.5a18.29,18.29,0,0,1-.7,3,7.3,7.3,0,0,0,3.91,1c3.84,0,6.24-2,6.24-5.28,0-5.2-7-4.56-7-7.94a2.3,2.3,0,0,1,2.52-2.14,2.79,2.79,0,0,1,2.76,2.07Z" />
      <path d="M106.8,26.41c1.24,1.29,2.49,2.69,3.66,4.09a1.26,1.26,0,0,1,.42-.06,2.33,2.33,0,0,1,.51.06v-.39l0-3.19c0-1.4,0-2.81.16-4.21l-.5,0a2,2,0,0,1-.52-.06c0,1,.08,1.92.08,2.89v2.52c-1.15-1.26-2.28-2.54-3.46-3.75a20.08,20.08,0,0,1-1.5-1.66,1.37,1.37,0,0,1-.44.06,2.41,2.41,0,0,1-.59-.06c0,.84,0,1.14,0,2.48a53,53,0,0,1-.21,5.35,2.25,2.25,0,0,1,.55-.06,2.05,2.05,0,0,1,.51.06c-.07-1-.09-1.67-.09-2.91s0-1.94,0-2.59l.29.29,1,1.12Z" />
      <path
        d="M140.13,30a4.48,4.48,0,0,0,2.62.67,4.11,4.11,0,0,0,4.45-4c0-2.71-1.69-4.11-4.34-4.11a4.06,4.06,0,0,0-4.41,4.19A3.73,3.73,0,0,0,140.13,30Zm2.73-6.82c1.58,0,2.44,1.24,2.44,3.45,0,2-1.05,3.38-2.55,3.38s-2.41-1.19-2.41-3.34c0-1.68.61-3.49,2.52-3.49Z"
        fillRule="evenodd"
      />
      <path
        d="M173.21,29c0,.52,0,.8-.1,1.53a5.56,5.56,0,0,1,.89,0,4.25,4.25,0,0,1,.95.08c0-.67-.06-.88-.06-1.24l0-1.17c0-.36,0-.7,0-1.06,0-.09,0-.15.08-.15s0,0,.12.17c.68,1.14,1.35,2.28,1.95,3.45.4,0,.57,0,.91,0l1.15,0-.51-.81-1-1.59c-.51-.79-.6-.94-.91-1.46,1-.28,1.84-.87,1.84-2,0-1.5-1.18-2-2.47-2-.6,0-1.21.06-1.81.06-.24,0-.4,0-1-.06.05,1.19.08,1.84.08,3,0,2.44,0,3.15,0,3.29Zm1.66-2.54c0-1,0-2.06,0-3.08l.36,0c1,0,1.41.47,1.41,1.44,0,1.21-.65,1.66-1.81,1.66Z"
        fillRule="evenodd"
      />
      <path d="M212.59,24.35a6.61,6.61,0,0,1,.3-1.14,5.17,5.17,0,0,0-2.74-.65c-2.68,0-4.71,1.48-4.71,4.29,0,2.28,1.77,3.79,4.44,3.79a15,15,0,0,0,3.18-.51c0-.61-.05-.85-.05-1.54A9.2,9.2,0,0,1,213.1,27a6.89,6.89,0,0,1-.9.06,5.74,5.74,0,0,1-.9-.06,13.32,13.32,0,0,1,.09,1.7c0,.39,0,.58,0,1.08a3.58,3.58,0,0,1-1.3.22c-1.69,0-2.77-1.25-2.77-3.23,0-2.18,1.15-3.53,3-3.53a2.57,2.57,0,0,1,2.12,1.13Z" />
      <path d="M241,26.77c1.29,0,1.76.05,2.64.11a1.45,1.45,0,0,1-.07-.49,1.36,1.36,0,0,1,.07-.46L241,26c0-.83.05-1.66.05-2.49.89,0,1.77.07,2.66.14a1.43,1.43,0,0,1-.07-.51,1.6,1.6,0,0,1,.07-.49c-.82.07-1.68.1-2.95.1a9.17,9.17,0,0,1-1.52-.1c.06,1.36.07,2.7.07,4.06s-.06,2.51-.1,3.77c.71,0,1.55-.06,2.36-.06s1.37,0,2.07.06a1.68,1.68,0,0,1,0-.44,5.13,5.13,0,0,1,0-.52,23.34,23.34,0,0,1-2.64.13c0-.46,0-.91,0-1.37V26.77Z" />
    </g>
  </svg>
);

export default EurojurisLogo;

import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { listWithoutStyle } from 'styleMixins/listMixins';
import createMediaQuery from 'util/createMediaQuery';

const ContentListSectionCss = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colorTokens.purple[700]};
  `};
`;

const ContentListCss = styled.ul`
  ${listWithoutStyle}

  margin: 0 auto;
  padding: 1.25rem;
  color: ${({ theme }) => theme.colorTokens.default.white};
  max-width: 50rem;
  text-align: center;

  ${createMediaQuery(
    'md',
    css`
      padding: 2rem 3.875rem;
      display: flex;
      justify-content: space-around;
      max-width: 90rem;
    `
  )}
`;

const ContentListItemCss = styled.li`
  padding: 1.25rem 0;
  flex: 0 0 33.33%;

  ${createMediaQuery(
    'md',
    css`
      padding: 2.5rem 0;
    `
  )}
`;

const ContentListCardCss = styled.div`
  text-align: center;
`;

const ContentListCardHeadingCss = styled.div`
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-size: 2.625rem;

  ${createMediaQuery(
    'md',
    css`
      font-size: 3.75rem;
    `
  )}
`;

const ContentListCardSourceCss = styled.div`
  font-size: 1.5rem;
  text-transform: uppercase;
`;

const DiagonalBooksSection: FC = () => (
  <ContentListSectionCss>
    <ContentListCss>
      <ContentListItemCss>
        <ContentListCardCss>
          <ContentListCardHeadingCss>90 +</ContentListCardHeadingCss>
          <ContentListCardSourceCss>Kommentarutgaver</ContentListCardSourceCss>
        </ContentListCardCss>
      </ContentListItemCss>
      <ContentListItemCss>
        <ContentListCardCss>
          <ContentListCardHeadingCss>330 +</ContentListCardHeadingCss>
          <ContentListCardSourceCss>Juridiske fagbøker</ContentListCardSourceCss>
        </ContentListCardCss>
      </ContentListItemCss>
      <ContentListItemCss>
        <ContentListCardCss>
          <ContentListCardHeadingCss>14 400 +</ContentListCardHeadingCss>
          <ContentListCardSourceCss>Fagartikler</ContentListCardSourceCss>
        </ContentListCardCss>
      </ContentListItemCss>
    </ContentListCss>
  </ContentListSectionCss>
);

export default DiagonalBooksSection;

import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const ItemCss = styled.li`
  font-size: 0.938rem;
  line-height: 2;

  &:not(:last-child):after {
    content: '/';
    margin: 0 0.625rem;
    color: ${({ theme }) => theme.colorTokens.grey[600]};
    font-weight: 900;
  }

  .link {
    color: ${({ theme }) => theme.colorTokens.grey[900]};
    font-size: 0.938rem;
  }

  .link.isActive {
    color: #5c5b5f;
    pointer-events: none;
    text-decoration: none;
  }
`;

export interface Breadcrumb {
  to: string;
  name: string | JSX.Element;
}

const BreadcrumbsItem: React.FC<{
  breadcrumb: Breadcrumb;
}> = ({ breadcrumb }) => (
  <ItemCss>
    <NavLink className="link" exact to={breadcrumb.to || ''} activeClassName="isActive">
      {breadcrumb.name}
    </NavLink>
  </ItemCss>
);

export default BreadcrumbsItem;

export const HOME_PAGE_DESCRIPTION =
  'Jusstjenesten som gir deg en mer effektiv arbeidsdag med rettskildesøk og enkle utdrag. Ajourførte lovkommentarer, fagbøker, nyheter og tidsskrifter - Alt på et sted. Juridiske nyheter. Faglitteratur. Rettskilder. Stiler: Ajourført, Søkbart, Nedlastbart.';

export const COMMENTARY_PAGE_DESCRIPTION =
  'Ajourførte kommentarer til lover, forskrifter og avtaler, basert på våre blå kommentarutgaver.';

export const TEXTBOOKS_PAGE_DESCRIPTION =
  'Universitetsforlagets juridiske fagbøker og lovkommentarer slik de ble utgitt i bokform.';

export const JOURNALS_PAGE_DESCRIPTION =
  'Universitetsforlagets tidsskrifter innen juss og tilstøtende fagområder, med utgaver fra og med 1962.';

export const AUTHORS_PAGE_DESCRIPTION = 'Forfatterprofiler og bibliografier for Universitetsforlagets jussforfattere.';

export const INSIGHT_PAGE_DESCRIPTION =
  'Vi skriver selv ulike typer aktualitets- og magasinsaker rettet mot jurister, og vi får ledende jusseksperter til å kommentere viktige dommer og lovendringer.';

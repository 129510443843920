import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import createMediaQuery from 'util/createMediaQuery';
import Image from 'components/Image';
import { linkWithoutStyle } from 'styleMixins/listMixins';
import Flex from 'components/ui/Flex';
import MediaWrapper from '../../../components/MediaWrapper';

const WhoWeAreSectionCss = styled.section`
  padding: 48px 0;
  background-color: ${({ theme }) => theme.colorTokens.default.white};

  ${createMediaQuery(
    'md',
    css`
      padding: 48px 0;
    `
  )}
`;

const WrapperCss = styled.div`
  margin: 0 auto;
  padding: 0 1.25rem;
  color: ${({ theme }) => theme.colorTokens.default.black};
  max-width: 50rem;

  ${createMediaQuery(
    'md',
    css`
      max-width: 90rem;
    `
  )}
`;

const TitleCss = styled.h2`
  margin: 0;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-size: 1.75rem;
  font-weight: 500;

  ${createMediaQuery(
    'md',
    css`
      font-size: 3.125rem;
    `
  )};
`;

const ContentCss = styled.div`
  ${createMediaQuery(
    'md',
    css`
      display: flex;
      justify-content: space-between;
    `
  )}

  .media {
    flex: 0 0 46.15%;
  }
`;

const TextCss = styled.div`
  font-size: 1.125rem;
  display: flex;
  justify-content: center;

  ul li {
    margin-top: 0.5rem;
  }

  ${createMediaQuery(
    'md',
    css`
      margin-top: 0;
      font-size: 1.375rem;
    `
  )}

  h3 {
    font-size: 1.25rem;
    font-weight: 600;

    ${createMediaQuery(
      'md',
      css`
        font-size: 1.75rem;
      `
    )}
  }
`;

const GetStartedLinkCss = styled.a`
  ${linkWithoutStyle}
  margin: 0 auto;
  margin-top: 25px;
  background-color: ${({ theme }) => theme.colorTokens.purple[700]};
  padding: 20px 30px;
  color: ${({ theme }) => theme.colorTokens.default.white};
  border-radius: 4px;
  display: inline-block;

  ${createMediaQuery(
    'md',
    css`
      margin-top: 3.5rem;
    `
  )}
`;

const WhoWeAreSection: FC = () => {
  return (
    <WhoWeAreSectionCss>
      <WrapperCss>
        <TitleCss>Dette gir Juridika deg:</TitleCss>
        <ContentCss>
          <TextCss>
            <ul>
              <li>Over 90 lovkommentarer som ajourføres jevnlig</li>
              <li>Over 300 av jussens viktigste fagbøker, tidsskrift og artikler skrevet av Norges ledende jussforfattere</li>
              <li>Fersk innsikt fra endringer i rettskilder og trender i jussen</li>
              <li>Ferdige maler, sjekklister og prosessbeskrivelser</li>
              <li>Mulighet til å skreddersy ditt eget abonnement</li>
            </ul>
          </TextCss>
          <MediaWrapper className="media">
            <Image src="https://dfwibl8ld7pfq.cloudfront.net/image/illustrations/forfatter-transparent.png" />
          </MediaWrapper>
        </ContentCss>
        <Flex align="center">
          <GetStartedLinkCss href="/priser">SE PRISER</GetStartedLinkCss>
        </Flex>
      </WrapperCss>
    </WhoWeAreSectionCss>
  );
};

export default WhoWeAreSection;

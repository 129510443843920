import * as React from 'react';

const RaederLogo: React.FC = () => (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 376.05 178.71">
    <g>
      <rect width="14.43" height="178.71" />
      <path d="M51.07,97.59A21.72,21.72,0,0,0,66.9,75.77q0-10.92-7.11-17.39T41.12,51.91H25.24V65h14.7q12.3,0,12.3,10.81t-12.3,10.7H25.24V99H36.31l15.08,27.81H67Z" />
      <path d="M180.07,126.79V51.91h25a32.51,32.51,0,0,1,12.09,2.25,32,32,0,0,1,10.27,6.63,30.68,30.68,0,0,1,7.32,11.72,47.71,47.71,0,0,1,2.73,16.74,52,52,0,0,1-2.78,17.86,27.49,27.49,0,0,1-7.6,11.72,32,32,0,0,1-10.43,6,37.58,37.58,0,0,1-12.24,1.92Zm14.45-13.37h9.95a22.43,22.43,0,0,0,6.74-.91,13.8,13.8,0,0,0,5.45-3.37,16.11,16.11,0,0,0,4-7.43A47,47,0,0,0,222,89.25q0-12.52-4.65-18.24a15,15,0,0,0-12.25-5.73H194.52Z" />
      <path d="M253.88,126.79V51.91h47.4V65.28H268.33v16.8h29.95V94.92H268.33v18.5h32.95v13.37Z" />
      <path d="M319.78,126.79V51.91h30.38q11.55,0,18.67,6.47t7.11,17.39a21.72,21.72,0,0,1-15.83,21.82l15.94,29.2H360.43L345.35,99H334.22v27.81Zm14.44-40.32H349q12.28,0,12.29-10.7T349,65H334.22Z" />
      <path d="M161.57,65.28V51.91H103L74.81,126.79H89.68L95.1,112h23.36v1.77h0v13.05h43.11V113.42H132.9V94.59h25.67V82.08H132.9V65.28Zm-49.42,0h6.31V99.14H99.78Z" />
    </g>
  </svg>
);

export default RaederLogo;

import * as React from 'react';

const ThommessenLogo: React.FC = () => (
  <svg version="1.1" id="_Layer_" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 396.9 85">
    <rect fill="none" width="396.9" height="85" />
    <path
      d="M266.8,22.6c-3-0.1-5.8,1-8,3.1c-1.7,1.6-2.6,3.9-2.5,6.2l0,0c0,3.4,2,6.5,5.6,8.7l6.6,3.9c2.7,1.6,5.4,3.7,5.4,7.6
	c0.1,1.8-0.6,3.6-1.9,4.9c-1.8,1.6-4.2,2.5-6.7,2.4c-2.5-0.1-4.9-0.5-7.2-1.4c-0.2-0.1-0.4-0.1-0.6-0.1c-0.3,0.1-0.4,0.3-0.6,0.7
	l-0.3,1c-0.1,0.2-0.2,0.5-0.1,0.8c0.1,0.4,0.6,0.5,0.8,0.6c2.5,0.9,5.2,1.3,7.9,1.3c7.2,0,11.8-4.1,11.8-10.3c0-5.4-3.5-8-7.3-10.3
	l-5.7-3.4c-2.8-1.7-4.6-3.5-4.6-6.9c0-3.9,3.9-5.9,7.5-5.9c2.2,0,4.4,0.4,6.4,1.2c0.2,0.1,0.5,0.2,0.7,0.1c0.3-0.1,0.5-0.3,0.5-0.5
	c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.2,0.1-0.4,0.1-0.6c0-0.3-0.3-0.6-0.6-0.8C272,23,269.4,22.5,266.8,22.6z"
    />
    <path
      d="M291.9,22.6c-3-0.1-5.8,1-8,3.1c-1.6,1.7-2.5,3.9-2.5,6.2l0,0c0,3.4,2,6.5,5.6,8.7c0,0,0,0,6.6,3.9c2.7,1.6,5.4,3.7,5.4,7.6
	c0.1,1.8-0.6,3.6-1.9,4.9c-1.8,1.6-4.2,2.5-6.7,2.4c-2.5-0.1-4.9-0.5-7.2-1.4c-0.2-0.1-0.4-0.1-0.6-0.1c-0.3,0.1-0.4,0.3-0.6,0.7
	l-0.3,1c-0.1,0.2-0.2,0.5-0.1,0.8c0.1,0.4,0.6,0.5,0.8,0.6c2.5,0.9,5.2,1.3,7.9,1.3c7.2,0,11.8-4.1,11.8-10.3c0-5.4-3.4-8-7.3-10.3
	l-5.6-3.4c-2.8-1.7-4.6-3.5-4.6-6.9c0-3.9,3.9-5.9,7.5-5.9c2.2,0,4.4,0.4,6.4,1.2c0.2,0.1,0.5,0.2,0.7,0.1c0.2-0.1,0.4-0.3,0.5-0.5
	c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.2,0.1-0.4,0.1-0.6c0-0.3-0.3-0.6-0.6-0.8C297.1,23,294.5,22.5,291.9,22.6z"
    />
    <path
      d="M112.8,62.5c8.4,0,13.5-2.4,13.5-6.4V28.4c0-4-4.9-6.2-13.5-6.2l0,0c-8.6,0-13.5,2.3-13.5,6.2v27.8
	C99.3,60.1,104.3,62.5,112.8,62.5 M112.8,59.6c-5.9,0-10.2-1.5-10.4-3.9V28.6c0.2-2.1,4.3-3.5,10.4-3.5l0,0c6.1,0,10.2,1.4,10.4,3.5
	v27.1C122.9,58.1,118.6,59.6,112.8,59.6"
    />
    <path
      d="M29.9,24.8c0,0.7,0.3,1,1,1h12v35.3c0,0.7,0.3,1,1,1h1.2c0.7,0,1-0.3,1-1V25.8h12.1c0.6,0.1,1.1-0.3,1.3-0.8l0.1-1.4
	c0-0.3-0.1-0.8-1-0.8H30.9c-0.7,0-1,0.3-1,1L29.9,24.8z"
    />
    <path
      d="M91.1,61c0,0.6-0.3,0.9-0.9,0.9H89c-0.6,0-0.9-0.3-0.9-0.9V42.9H66.8v18.2c0,0.6-0.3,0.9-0.9,0.9h-1.2
	c-0.6,0-0.9-0.3-0.9-0.9V23.8c0-0.6,0.3-0.9,0.9-0.9h1.3c0.6,0,0.9,0.3,0.9,0.9v16H88v-16c0-0.6,0.3-0.9,0.9-0.9h1.3
	c0.6,0,0.9,0.3,0.9,0.9L91.1,61z"
    />
    <path
      d="M154.6,61.9c0.4,0,0.8-0.2,0.9-0.6c0.1-0.3,11.3-26,13.1-30.3c0.2,4,1.4,14.5,2.4,22.8c0.2,1.6,0.2,3.1,0.2,4.7
	c0,0.9-0.1,2.5-0.1,2.5c0,0.6,0.3,0.9,0.8,0.9h1.4c0.4,0.1,0.8-0.2,0.9-0.7c0-0.1,0-0.1,0-0.2v-0.4c0,0,0-1.1,0-3.1
	c0-1.2,0-2.6-0.1-4c-0.3-3.5-3.1-29.3-3.1-29.6v-0.1c-0.1-1-0.6-1-0.9-1h-1.1c-0.5,0-0.7,0.3-0.9,0.8l-13.8,33.1l0,0
	c-0.2-0.5-13.8-33.1-13.8-33.1c-0.2-0.5-0.4-0.8-0.9-0.8h-1.1c-0.3,0-0.9,0-0.9,1v0.1c0,0.2-2.8,26.1-3.1,29.6
	c-0.1,1.3-0.1,2.7-0.1,4c0,2,0.1,3.1,0.1,3.1V61c0,0.4,0.3,0.8,0.7,0.9c0.1,0,0.1,0,0.2,0h1.4c0.5,0,0.8-0.3,0.8-0.9
	c0,0-0.1-1.7-0.1-2.5c-0.1-1.6,0-3.1,0.2-4.7c1-8.3,2.2-18.8,2.4-22.8c1.8,4.3,13,30,13.1,30.3c0.1,0.4,0.5,0.7,0.9,0.6H154.6z"
    />
    <path
      d="M201.8,61.9c0.4,0,0.8-0.2,0.9-0.6c0.1-0.3,11.3-26,13.1-30.3c0.2,4,1.4,14.5,2.4,22.8c0.2,1.6,0.2,3.1,0.2,4.7
	c0,0.9-0.1,2.5-0.1,2.5c0,0.6,0.3,0.9,0.8,0.9h1.4c0.4,0.1,0.8-0.2,0.9-0.7c0-0.1,0-0.1,0-0.2v-0.4c0,0,0-1.1,0-3.1
	c0-1.2,0-2.6-0.1-4c-0.3-3.5-3.1-29.3-3.1-29.6v-0.1c-0.1-1-0.6-1-0.9-1H216c-0.5,0-0.7,0.3-0.9,0.8c0,0-13.6,32.6-13.8,33.1l0,0
	l-13.8-33.1c-0.2-0.5-0.4-0.8-0.9-0.8h-1.1c-0.3,0-0.9,0-0.9,1v0.1c0,0.2-2.9,26.1-3.1,29.6c-0.1,1.3-0.1,2.7-0.1,4c0,2,0,3.1,0,3.1
	V61c0,0.4,0.3,0.8,0.7,0.9c0.1,0,0.1,0,0.2,0h1.4c0.5,0,0.8-0.3,0.8-0.9c0,0-0.1-1.7-0.1-2.5c-0.1-1.6,0-3.1,0.2-4.7
	c1-8.3,2.2-18.8,2.4-22.8c1.8,4.3,13,30,13.1,30.3c0.1,0.4,0.5,0.7,0.9,0.6H201.8z"
    />
    <path
      d="M248.3,42.9c0.6,0,0.9-0.3,0.9-0.9v-1.3c0-0.6-0.3-0.9-0.9-0.9H232c0-0.2,0-13.1,0-13.4l0.7-0.1c2.7-0.4,5.4-0.5,8.1-0.6
	c3.2,0.1,6.4,0.3,9.6,0.7c0.2,0,0.5,0,0.7-0.2c0.2-0.1,0.3-0.3,0.3-0.6l0.1-1.5c0-0.2,0-0.4-0.1-0.6c-0.2-0.1-0.4-0.2-0.6-0.2
	c-3.3-0.4-6.6-0.7-9.9-0.7c-3.5,0-7,0.2-10.5,0.7c-0.9,0.1-1.3,0.5-1.3,1.4v35.5c0,0.8,0.4,1,1.3,1.2c3.6,0.5,7.2,0.7,10.9,0.8
	c3.2,0,6.4-0.3,9.6-0.7c0.6-0.1,0.8-0.4,0.8-0.8l-0.1-1.6c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.2-0.5-0.2-0.7-0.1c-3.1,0.4-6.2,0.7-9.3,0.8
	c-2.8,0-5.7-0.3-8.5-0.7l-0.6-0.1V42.9H248.3z"
    />
    <path
      d="M327.6,42.9c0.6,0,0.9-0.3,0.9-0.9v-1.3c0.1-0.4-0.2-0.8-0.7-0.9c-0.1,0-0.1,0-0.2,0h-16.4c0-0.2,0-13.1,0-13.4l0.7-0.1
	c2.7-0.4,5.4-0.5,8.1-0.6c3.2,0.1,6.4,0.3,9.6,0.7c0.2,0,0.5,0,0.7-0.2c0.2-0.1,0.3-0.3,0.3-0.6l0.1-1.5c0-0.2,0-0.4-0.1-0.6
	c-0.2-0.1-0.4-0.2-0.6-0.2c-3.3-0.4-6.6-0.7-9.9-0.7c-3.5,0-7,0.2-10.5,0.7c-0.9,0.1-1.3,0.5-1.3,1.4v35.5c0,0.8,0.4,1,1.3,1.2
	c3.6,0.5,7.2,0.7,10.9,0.8c3.2,0,6.4-0.2,9.6-0.7c0.6-0.1,0.8-0.4,0.8-0.8l-0.1-1.6c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.2-0.5-0.2-0.7-0.1
	c-3.1,0.4-6.2,0.7-9.3,0.8c-2.8,0-5.7-0.3-8.5-0.7l-0.6-0.1V42.9H327.6z"
    />
    <path
      d="M365.8,23.6c0-0.6-0.3-0.9-0.9-0.9h-1.2c-0.6,0-0.9,0.3-0.9,0.9v31.9l-22.8-32.1c-0.2-0.3-0.5-0.6-0.9-0.6h-1.2
	c-0.6,0-0.9,0.3-0.9,0.9v37.3c0,0.6,0.3,0.9,0.9,0.9h1.2c0.6,0,0.9-0.3,0.9-0.9V28.8l22.6,32.1c0.4,0.5,0.6,0.9,1.2,0.9h1.1
	c0.4,0,0.8-0.3,0.9-0.7c0-0.1,0-0.1,0-0.2L365.8,23.6z"
    />
  </svg>
);

export default ThommessenLogo;
